import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import { Link, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useAuth } from '../services/AuthContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { header } from '../services/colors';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';

export default function Navbar(props) {
  const { currentUser, logout } = useAuth();

  const { drawerWidth, content, user } = props;
  const location = useLocation();
  const path = location.pathname;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const first_name = currentUser ? currentUser.firstName : '';
  const last_name = currentUser ? currentUser.lastName : '';
  const role = currentUser ? currentUser.role : '';
  const userInfo = currentUser || user;
  const isAdmin = userInfo && userInfo.role === 'Admin';
  const isCustodian = userInfo && userInfo.role === 'Fund Custodian';
  const isCostController = userInfo && userInfo.role === 'Cost Controller';
  const isFinanceManager = userInfo && userInfo.role === 'Finance Manager';

  const changeOpenStatus = () => {
    setOpen(!open);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutConfirmed = () => {
    logout();
  };

  const theme = createTheme({
    typography: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeightLight: 300,
    },
  });

  const myDrawer = (
    <div>
      <ThemeProvider theme={theme}>
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {isAdmin && (
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/Home" selected={path === '/Home'}>
                  <ListItemIcon>
                    <HomeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItemButton>
              </ListItem>
            )}
            {isAdmin && (
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/businessUnitList" selected={path === '/businessUnitList'}>
                  <ListItemIcon>
                    <BusinessOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Company" />
                </ListItemButton>
              </ListItem>
            )}
            {isAdmin && (
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/UserManagement" selected={path === '/UserManagement'}>
                  <ListItemIcon>
                    <PersonOutlineOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="User Management" />
                </ListItemButton>
              </ListItem>
            )}
            {isAdmin && (
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/fund" selected={path === '/fund'}>
                  <ListItemIcon>
                    <PaidOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Fund" />
                </ListItemButton>
              </ListItem>
            )}
            {isAdmin && (
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/AssignBusinessUnit" selected={path === '/AssignBusinessUnit'}>
                  <ListItemIcon>
                    <BusinessOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Fund Assignment" />
                </ListItemButton>
              </ListItem>
            )}
            {isCostController && (
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/CostControllerDashboard" selected={path === '/CostControllerDashboard'}>
                  <ListItemIcon>
                    <HomeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
              </ListItem>
            )}
            {isCostController && (
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/purchaseRequestList" selected={path === '/purchaseRequestList'}>
                  <ListItemIcon>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Fund Request" />
                </ListItemButton>
              </ListItem>
            )}
            {isFinanceManager && (
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/Dashboard" selected={path === '/Dashboard'}>
                  <ListItemIcon>
                    <HomeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
              </ListItem>
            )}
            {isFinanceManager && (
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/GeneralManagerDashboard" selected={path === '/GeneralManagerDashboard'}>
                  <ListItemIcon>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Fund Request List" />
                </ListItemButton>
              </ListItem>
            )}
            {isCustodian && (
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/CustodianDashboard" selected={path === '/CustodianDashboard'}>
                  <ListItemIcon>
                    <HomeOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
              </ListItem>
            )}
            {isCustodian && (
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/fundAllocation" selected={path === '/fundAllocation'}>
                  <ListItemIcon>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Fund Allocation" />
                </ListItemButton>
              </ListItem>
            )}
            {isCustodian && (
              <ListItem disablePadding>
                <ListItemButton component={Link} to="/Reports" selected={path === '/Reports'}>
                  <ListItemIcon>
                    <InsertChartOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem disablePadding>
              <ListItemButton onClick={handleLogoutConfirmed} component={Link} to="/">
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </ThemeProvider>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: header, boxShadow: 'none' }}>
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <IconButton
              color="inherit"
              onClick={changeOpenStatus}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Petty Cash
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: 'auto' }}>
              <div>
                <Typography variant="body1" sx={{ marginRight: '8px' }}>
                  {`${first_name} ${last_name}`}
                </Typography>
                <Typography variant="body1" sx={{ marginRight: '8px', fontSize: '0.8rem', textAlign: 'center', color: '#ccc' }}>
                  {`${role}`}
                </Typography>
              </div>
              <IconButton
                color="inherit"
                onClick={handleMenuClick}
                sx={{ display: { xs: 'none', sm: 'block' } }}
              >
                <AccountCircleIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleLogoutConfirmed}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: '#9BBEC8' },
          }}
        >
          {myDrawer}
        </Drawer>
        <Drawer
          variant="temporary"
          open={open}
          onClose={changeOpenStatus}
          sx={{
            display: { xs: 'block', sm: 'none' },
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: '#04668b' },
          }}
        >
          {myDrawer}
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          {content}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

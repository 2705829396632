import React, { useState, useEffect } from 'react';
import AxiosInstance from '../Axios';
import { ToastContainer, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBusinessUnit, editBusinessUnit, deleteBusinessUnit } from '../services/BusinessUnitService';
import Toast from '../services/ToastService';
import Loader from '../services/Loader';
const BusinessUnitList = () => {
  const [businessUnits, setBusinessUnits] = useState([]);
  const [editedBusinessUnit, setEditedBusinessUnit] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15);
  const [newBusinessUnit, setNewBusinessUnit] = useState({
    business_unit_name: '',

  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBusinessUnits = async () => {
      try {
        const response = await AxiosInstance.get('/api/businessUnit_list/');
        setBusinessUnits(response.data);
        setLoading(false);
      } catch (error) {
        Toast({ message: 'Error fetching data', type: 'error' });
        setLoading(false);
      }
    };

    fetchBusinessUnits();
  }, []);

  if (loading) {
    return <Loader />;
  }



  const handleCreateBusinessUnit = async () => {
    try {
      await createBusinessUnit(newBusinessUnit);
      const updatedResponse = await AxiosInstance.get('/api/businessUnit_list/');
      setBusinessUnits(updatedResponse.data);

      setNewBusinessUnit({
        business_unit_name: '',
      });

      Toast({ message: 'Company created successfully.', type: 'success' });
    } catch (error) {
      Toast({ message: 'Error creating Business unit.', type: 'error' });
      if (error.response) {
        Toast({ message: 'Server response data.', type: 'error' });
      }
    }
  };

  const handleEdit = (businessUnitId) => {
    const businessUnitToEdit = businessUnits.find((businessUnit) => businessUnit.id === businessUnitId);
    setEditedBusinessUnit(businessUnitToEdit);
  };

  const handleClose = () => {
    setEditedBusinessUnit(false);
  };


  const EditBusinessUnit = async () => {
    try {

      const isEmptyField = Object.values(editedBusinessUnit).some(value => !value);
      if (isEmptyField) {
        Toast({ message: 'Please fill in all fields before editing.', type: 'error' });
        return;
      }

      if (editedBusinessUnit) {
        await editBusinessUnit(editedBusinessUnit.id, editedBusinessUnit);

        setEditedBusinessUnit(null);
        const updatedResponse = await AxiosInstance.get('/api/businessUnit_list/');
        setBusinessUnits(updatedResponse.data);
        Toast({ message: 'Business edited successfully.', type: 'success' });
      } else {
        Toast({ message: 'No business unit data to edit.', type: 'error' });
      }
    } catch (error) {
      let errorMessage = 'Error editing business unit.';
      if (error.response) {
        errorMessage = 'Server response data.';
      }
      Toast({ message: errorMessage, type: 'error' });
    }
  };

  const handleDeleteBusinessUnit = async (businessUnitId) => {
    try {
      const isConfirmed = window.confirm('Are you sure you want to delete this Company?');
      if (isConfirmed) {
        await deleteBusinessUnit(businessUnitId);
        Toast({ message: 'Company deleted successfully.', type: 'success' });

        const updatedResponse = await AxiosInstance.get('/api/businessUnit_list/');
        setBusinessUnits(updatedResponse.data);

      } else {
        Toast({ message: 'Fund deletion cancelled.', type: 'success' });
      }
    } catch (error) {
      Toast({ message: 'Error deleting Business Unit.', type: 'error' });
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = businessUnits.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(businessUnits.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  //Css style
  const formContainerStyle = {
    marginBottom: '10px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    maxWidth: '80%',
    margin: 'auto',
    background: '#f9fafb',
  };

  const flexContainerStyle = {
    display: 'flex',
    marginBottom: '8px',
  };

  const flexItemStyle = {
    flex: 1,
    marginRight: '8px',
  };

  const inputStyle = {
    width: '100%',
    padding: '8px',
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
  };


  const buttonStyle = {
    background: '#4CAF50',
    color: 'white',
    border: 'none',
    padding: '8px 12px',
    cursor: 'pointer',
    borderRadius: '4px',
    backgroundColor: "#52ab98"
  };

  return (
    <div>
      <h2>Create Company</h2>
      <form style={formContainerStyle}>
        <div style={flexContainerStyle}>
          <div style={{ ...flexItemStyle }}>
            <label htmlFor="createBusinessUnit">Company Name:</label>
            <input
              style={inputStyle}
              type="text"
              id="createBusinessUnit"
              value={newBusinessUnit.business_unit_name}
              onChange={(e) => setNewBusinessUnit({ ...newBusinessUnit, business_unit_name: e.target.value })}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button style={buttonStyle} type="button" onClick={handleCreateBusinessUnit}>
            Create
          </button>
        </div>
      </form>
      <br />

      <div style={formContainerStyle}>
        <table style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid #ddd', background: '#f2f2f2' }}>
              <th>No</th>
              <th>Company Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentItems
              .slice()
              .sort((a, b) => (a.business_unit_name > b.business_unit_name) ? 1 : -1)
              .map((businessUnit, index) => (
                <tr key={businessUnit.id} style={{ borderBottom: '1px solid #ddd' }}>
                  <td style={{ textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ textAlign: 'center' }}>{businessUnit.business_unit_name}</td>
                  <td style={{ textAlign: 'center' }}>
                    <button type="button"
                      style={{ marginRight: '8px', background: '#52ab98', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px' }}
                      onClick={() => handleEdit(businessUnit.id)}>
                      Edit
                    </button>
                    <button type="button"
                      style={{ background: '#f44336', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px' }}
                      onClick={() => handleDeleteBusinessUnit(businessUnit.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>


      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        {pageNumbers.map((number) => (
          <button key={number} onClick={() => paginate(number)}
            style={{
              backgroundColor: currentPage === number ? '#9FA6B2' : '#9FA6B280',
              color: 'white',
              border: 'none',
              padding: '5px 7px',
              cursor: 'pointer',
              borderRadius: '4px',
              marginRight: '160px',
            }}>
            {number}
          </button>
        ))}
      </div>

      {editedBusinessUnit && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: '#fff',
              padding: '16px',
              borderRadius: '4px',
              width: '50%',
              overflow: 'auto',
              maxHeight: '80%',
            }}
          >

            <form>
              <h2>Edit Company Name:</h2>
              <div style={flexContainerStyle}>
                <div style={{ ...flexItemStyle }}>
                  <label htmlFor="editBusinessUnit">Company Name:</label>
                  <input
                    style={inputStyle}
                    type="text"
                    id="editBusinessUnit"
                    value={editedBusinessUnit.business_unit_name}
                    onChange={(e) =>
                      setEditedBusinessUnit({ ...editedBusinessUnit, business_unit_name: e.target.value })
                    }
                  />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', marginRight: '5px' }}>
                <button type="button" style={{ ...buttonStyle, marginRight: '5px' }} onClick={EditBusinessUnit}>
                  Save
                </button>
                <button style={{ background: '#9FA6B2', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', marginRight: '5px' }} onClick={handleClose}>Close</button>
              </div>
            </form>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default BusinessUnitList;

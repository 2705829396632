// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './components/services/AuthContext';
import Home from './components/adminModule/Home';
import BusinessUnitList from './components/adminModule/BusinessUnit';
import UserManagement from './components/adminModule/UserManagement';
import Navbar from './components/adminModule/NavBar';
import FundAllocation from './components/FundCustodianModule/FundAllocation';
import Fund from './components/adminModule/Fund';
import Login from './components/Login';
import PurchaseRequestList from './components/EncoderModule/PuchaseRequest';
import AssignBusinessUnit from './components/adminModule/AssignBusinessUnit';
import CostControllerDashboard from './components/CostControllerModule/CostControllerDashboard';
import GeneralManagerDashboard from './components/GeneralManagerModule/GeneralManagerDashboard';
import CustodianDashboard from './components/FundCustodianModule/CustodianDashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToBeReleaseCard from './components/FundCustodianModule/ToBeReleaseCard';
import ReleaseCard from './components/FundCustodianModule/ReleaseCard';
import LiquidatedCard from './components/FundCustodianModule/LiquidatedCard';
import { useAuth } from './components/services/AuthContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CostControllerApproved from './components/EncoderModule/CostControllerApproved';
import CostControllerDecline from './components/EncoderModule/CostControllerDecline';
import CostControllerLiquidated from './components/EncoderModule/CostControllerLiquidated';
import CostControllerReleased from './components/EncoderModule/CostControllerReleased';
import Reports from './components/FundCustodianModule/Reports';
import GeneralManagerApproved from './components/GeneralManagerModule/GeneralManagerApproved';
import GeneralManagerDeclined from './components/GeneralManagerModule/GeneralManagerDeclined';
import GeneralManagerReleased from './components/GeneralManagerModule/GeneralManagerReleased';
import GeneralManagerLiquidated from './components/GeneralManagerModule/GeneralManagerLiquidated';
import Dashboard from './components/GeneralManagerModule/GeneralManager';
import GeneralManagerReplenished from './components/GeneralManagerModule/GeneralManagerReplenished';
function App() {
  const mywidth = 240;
  const { currentUser } = useAuth();
  const userInfo = currentUser || {};
  const roleMapping = {
    isAdmin: 'Admin',
    isCustodian: 'Fund Custodian',
    isCostController: 'Cost Controller',
    isFinanceManager: 'Finance Manager',
  };

  const theme = createTheme({
    typography: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeightLight: 300,
    },
  });

  const userRoles = Object.entries(roleMapping).reduce((acc, [key, value]) => {
    acc[key] = userInfo && userInfo.role === value;
    return acc;
  }, {});

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <div className="App">
            <Routes>
              {userRoles.isAdmin && (
                <Route path="/Home" element={<Navbar drawerWidth={mywidth} content={<Home />} />} />
              )}
              {userRoles.isCostController && (
                <Route path="/CostControllerDashboard" element={<Navbar drawerWidth={mywidth} content={<CostControllerDashboard />} />} />
              )}
              {userRoles.isFinanceManager && (
                <Route path="/GeneralManagerDashboard" element={<Navbar drawerWidth={mywidth} content={<GeneralManagerDashboard />} />} />
              )}
              {userRoles.isCustodian && (
                <Route path="/CustodianDashboard" element={<Navbar drawerWidth={mywidth} content={<CustodianDashboard />} />} />
              )}
              {userRoles.isCustodian && (
                <Route path="/ToBeReleaseCard/:1" element={<Navbar drawerWidth={mywidth} content={<ToBeReleaseCard />} />} />
              )}
              {userRoles.isCustodian && (
                <Route path="/ReleaseCard/:2" element={<Navbar drawerWidth={mywidth} content={<ReleaseCard />} />} />
              )}
              {userRoles.isCustodian && (
                <Route path="/LiquidatedCard/:3" element={<Navbar drawerWidth={mywidth} content={<LiquidatedCard />} />} />
              )}
              {userRoles.isCustodian && (
                <Route path="/Reports/" element={<Navbar drawerWidth={mywidth} content={<Reports />} />} />
              )}
              {userRoles.isAdmin && (
                <Route path="/UserManagement" element={<Navbar drawerWidth={mywidth} content={<UserManagement />} />} />
              )}
              {userRoles.isAdmin && (
                <Route path="/Fund" element={<Navbar drawerWidth={mywidth} content={<Fund />} />} />
              )}
              {userRoles.isAdmin && (
                <Route path="/AssignBusinessUnit" element={<Navbar drawerWidth={mywidth} content={<AssignBusinessUnit />} />} />
              )}
              {userRoles.isAdmin && (
                <Route path="/BusinessUnitList" element={<Navbar drawerWidth={mywidth} content={<BusinessUnitList />} />} />
              )}
              {userRoles.isCustodian && (
                <Route path="/FundAllocation" element={<Navbar drawerWidth={mywidth} content={<FundAllocation />} />} />
              )}
              {userRoles.isCostController && (
                <Route path="/PurchaseRequestList" element={<Navbar drawerWidth={mywidth} content={<PurchaseRequestList />} />} />
              )}
              {userRoles.isCostController && (
                <Route path="/CostControllerApproved/:5" element={<Navbar drawerWidth={mywidth} content={<CostControllerApproved />} />} />
              )}
              {userRoles.isCostController && (
                <Route path="/CostControllerLiquidated/:6" element={<Navbar drawerWidth={mywidth} content={<CostControllerLiquidated />} />} />
              )}
              {userRoles.isCostController && (
                <Route path="/CostControllerReleased/:7" element={<Navbar drawerWidth={mywidth} content={<CostControllerReleased />} />} />
              )}
              {userRoles.isCostController && (
                <Route path="/CostControllerDecline/:9" element={<Navbar drawerWidth={mywidth} content={<CostControllerDecline />} />} />
              )}
              {userRoles.isFinanceManager && (
                <Route path="/GeneralManagerApproved/:11" element={<Navbar drawerWidth={mywidth} content={<GeneralManagerApproved />} />} />
              )}
              {userRoles.isFinanceManager && (
                <Route path="/GeneralManagerDeclined/:12" element={<Navbar drawerWidth={mywidth} content={<GeneralManagerDeclined />} />} />
              )}
              {userRoles.isFinanceManager && (
                <Route path="/GeneralManagerReleased/:13" element={<Navbar drawerWidth={mywidth} content={<GeneralManagerReleased />} />} />
              )}
              {userRoles.isFinanceManager && (
                <Route path="/GeneralManagerLiquidated/:14" element={<Navbar drawerWidth={mywidth} content={<GeneralManagerLiquidated />} />} />
              )}
              {userRoles.isFinanceManager && (
                <Route path="/GeneralManagerReplenished/:15" element={<Navbar drawerWidth={mywidth} content={<GeneralManagerReplenished />} />} />
              )}
              {userRoles.isFinanceManager && (
                <Route path="/Dashboard" element={<Navbar drawerWidth={mywidth} content={<Dashboard />} />} />
              )}
              <Route path="/" element={<Login />} />
              <Route path="/" element={<Navigate to="/" />} />
            </Routes>

            <ToastContainer />
          </div>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;

import AxiosInstance from '../Axios';


export const approvePurchaseRequest = async (PurchaseRequestId, updatedPurchaseRequest) => {
    try {
      const response = await AxiosInstance.put(`/api/forms_list/${PurchaseRequestId}/`, updatedPurchaseRequest); 
      return response.data;
    } catch (error) {
      console.error('Error editing user:', error);
      throw error;
    }
  };

  export const Released = async (ReleasedId, updatedReleased) => {
    try {
      const response = await AxiosInstance.put(`/api/Fund_Custodian_Release_Amount/${ReleasedId}/`, updatedReleased);
      return response.data;
    } catch (error) {
      console.error('Error editing user:', error);
      throw error;
    }
  };


  export const ReplenishPurchaseRequest = async (ReplenishPurchaseRequestId, updatedReplenishPurchaseRequest) => {
    try {
      const response = await AxiosInstance.put(`/api/replenish_function/${ReplenishPurchaseRequestId}/`, updatedReplenishPurchaseRequest); 
      return response.data;
    } catch (error) {
      console.error('Error editing purchase request:', error);
      throw error;
    }
  };

  export const declinePurchaseRequest = async (DeclinePurchaseRequestId, DeclinePurchaseRequestData) => {
    try {
      const response = await AxiosInstance.put(`/api/decline_return_fund_function/${DeclinePurchaseRequestId}/`, DeclinePurchaseRequestData);
      return response.data;
    } catch (error) {
      console.error('Error editing purchase request:', error);
      throw error;
    }
  };

  export const Excess_or_Refund = async (Excess_or_RefundId, updatedExcess_or_Refund) => {
    try {
      const response = await AxiosInstance.put(`/api/excess_or_refund_function/${Excess_or_RefundId}/`, updatedExcess_or_Refund);
      return response.data;
    } catch (error) {
      console.error('Error editing purchase request:', error);
      throw error;
    }
  };
  

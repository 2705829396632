import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Toast from '../services/ToastService';
import AxiosInstance from '../Axios';
import Loader from '../services/Loader';
import { useAuth } from '../services/AuthContext';

const CostControllerDashboard = () => {
  const history = useNavigate();
  const { currentUser } = useAuth();

  const [DeclinedPurchaseRequest, setDeclinedPurchaseRequest] = useState([]);
  const [ApprovedPurchaseRequest, setApprovedPurchaseRequest] = useState([]);
  const [LiquidatedPurchaseRequest, setLiquidatedPurchaseRequest] = useState([]);
  const [ReleasedPurchaseRequest, setReleasedPurchaseRequest] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchfund = async () => {
      try {

        const currentUserBusinessUnit = currentUser.business_unit;

        const Declinedresponse = await AxiosInstance.get('/api/PurchaseRequest_Decline_List_View/');
        const declinedPurchaseRequests = Declinedresponse.data;
        const filteredDeclinedPurchaseRequests = declinedPurchaseRequests.filter(request => request.business_unit_name === currentUserBusinessUnit);
        setDeclinedPurchaseRequest(filteredDeclinedPurchaseRequests);

        const approvedResponse = await AxiosInstance.get('/api/PurchaseRequestApprovedListView/');
        const approvedPurchaseRequests = approvedResponse.data;
        const filteredApprovedPurchaseRequests = approvedPurchaseRequests.filter(request => request.business_unit_name === currentUserBusinessUnit);
        setApprovedPurchaseRequest(filteredApprovedPurchaseRequests);

        const Liquidatedresponse = await AxiosInstance.get('/api/Encoder_Liquidated_List_View/');
        const liquidatedPurchaseRequests = Liquidatedresponse.data;
        const filteredLiquidatedPurchaseRequests = liquidatedPurchaseRequests.filter(request => request.business_unit_name === currentUserBusinessUnit);
        setLiquidatedPurchaseRequest(filteredLiquidatedPurchaseRequests);

        const Releasedresponse = await AxiosInstance.get('/api/PurchaseRequestReleasedListView/');
        const releasedPurchaseRequests = Releasedresponse.data;
        const filteredReleasedPurchaseRequests = releasedPurchaseRequests.filter(request => request.business_unit_name === currentUserBusinessUnit);
        setReleasedPurchaseRequest(filteredReleasedPurchaseRequests);


        setLoading(false);
      } catch (error) {
        Toast({ message: 'Error fetching data from server.', type: 'error' });
        setLoading(false);
      }
    };

    fetchfund();
  }, [currentUser.business_unit]);

  if (loading) {
    return <Loader />;
  }

  const blue_card = '#fff';

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      backgroundColor: '#E6F7FF',
    },

    flexContainer1: {
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'center',
    },
    cardRow: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px',
    },
    card: {
      width: 'calc(33.33% - 20px)',
      borderRadius: '8px',
      margin: '10px',
      backgroundColor: blue_card,
      border: '1px solid #ccc',
    },
    smallCard: {
      width: 'calc(33.33% - 20px)',
      padding: '10px',
      borderRadius: '8px',
      backgroundColor: blue_card,
      margin: '10px',
      height: '25vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #ccc',
    },
    smallHeading: {
      textAlign: 'center',
      color: '#000',
      marginBottom: '8px',
    },
  };

  const CostControllerApproved = () => {
    history(`/CostControllerApproved/${5}`);
  };
  const CostControllerLiquidated = () => {
    history(`/CostControllerLiquidated/${6}`);
  };


  const CostControllerReleased = () => {
    history(`/CostControllerReleased/${7}`);
  };


  const CostControllerDecline = () => {
    history(`/CostControllerDecline/${9}`);
  };

  return (
    <div >
      <div style={styles.flexContainer1}>
        <div style={styles.smallCard} onClick={() => CostControllerApproved()}>
          <h3 style={styles.smallHeading}>Approved Fund Request </h3>
          <div style={{ textAlign: 'center' }}>
            <h2>{ApprovedPurchaseRequest.length}</h2>
          </div>
        </div>

        <div style={styles.smallCard} onClick={() => CostControllerDecline()}>
          <h3 style={styles.smallHeading}>Declined Fund Request </h3>
          <div style={{ textAlign: 'center' }}>
            <h2>{DeclinedPurchaseRequest.length}</h2>
          </div>
        </div>


        <div style={styles.smallCard} onClick={() => CostControllerReleased()}>
          <h3 style={styles.smallHeading}>Disbursed Fund Request</h3>
          <div style={{ textAlign: 'center' }}>
            <h2>{ReleasedPurchaseRequest.length}</h2>
          </div>
        </div>

        <div style={styles.smallCard} onClick={() => CostControllerLiquidated()}>
          <h3 style={styles.smallHeading}>Liquidated</h3>
          <div style={{ textAlign: 'center' }}>
            <h2>{LiquidatedPurchaseRequest.length}</h2>
          </div>
        </div>

      </div>
    </div>
  );
};


export default CostControllerDashboard;


import { } from "../services/AuthContext";
import React, { useState, useEffect } from 'react';
import Toast from '../services/ToastService';
import AxiosInstance from '../Axios';
import Loader from '../services/Loader';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
const Home = () => {
  const [fund_per_business, set_fund_per_business] = useState([]);
  const [ReleaseAmount, setReleaseAmount] = useState([]);
  const [LiquidatedAmount, setLiquidatedAmount] = useState([]);
  const [fundLog, setfundLog] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchfund = async () => {
      try {

        const businessResponse = await AxiosInstance.get('/api/fund_list/');
        set_fund_per_business(businessResponse.data);

        const fundLogResponse = await AxiosInstance.get('/api/fund_list/FundLog_view/');
        setfundLog(fundLogResponse.data);

        const released = await AxiosInstance.get('/api/admin_released/');
        setReleaseAmount(released.data);

        const liquidated = await AxiosInstance.get('/api/admin_liquidated/');
        setLiquidatedAmount(liquidated.data);

        setLoading(false);
      } catch (error) {
        Toast({ message: 'Error fetching data', type: 'error' });
      }
    };

    fetchfund();
  }, []);

  if (loading) {
    return <Loader />;
  }


  const getStartingfund = () => {
    return [...new Set(fundLog.map((FundLog) => FundLog.fund_name))];
  };

  //total Release Per Fund
  const totalReleasedAmountByFund = calculateTotalReleasedAmountByFund();

  function calculateTotalReleasedAmountByFund() {
    const totals = {};
    ReleaseAmount.forEach((releaseAmount) => {
      const { fund_name, amount } = releaseAmount;
      totals[fund_name] = (totals[fund_name] || 0) + parseFloat(amount);
    });
    return totals;
  }

  const getReleasedFunds = () => {
    return [...new Set(ReleaseAmount.map((releaseAmount) => releaseAmount.fund_name))];
  };

  //total Liquidated Per Fund
  const totalLiquidatedAmountByFund = calculateTotalLiquidatedAmountByFund();

  function calculateTotalLiquidatedAmountByFund() {
    const totals = {};
    LiquidatedAmount.forEach((liquidatedAmount) => {
      const { fund_name, amount } = liquidatedAmount;
      totals[fund_name] = (totals[fund_name] || 0) + parseFloat(amount);
    });
    return totals;
  }

  const getLiquidatedFunds = () => {
    return [...new Set(LiquidatedAmount.map((liquidatedAmount) => liquidatedAmount.fund_name))];
  };


  function formatAmountWithCommas(amount) {
    if (amount !== undefined && amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "";
    }
  }


  return (
    <div>
      <div style={styles.flexContainer}>

        <div style={styles.smallCard}>
          <h3 style={styles.smallHeading}>Starting Fund </h3>
          {fundLog
            .slice()
            .sort((a, b) => (a.fund_name > b.fund_name) ? 1 : -1)
            .map((fund) => (
              <div key={fund.id} style={{ marginBottom: '2px' }}>
                <p style={styles.smallParagraph}>
                  <strong>{fund.fund_name}</strong> - ₱ {formatAmountWithCommas(parseFloat(fund.amount).toFixed(2))}
                </p>
              </div>
            ))}
        </div>

        <div style={styles.smallCard}>
          <h3 style={styles.smallHeading}>Remaining Fund </h3>
          {fund_per_business
            .slice()
            .sort((a, b) => (a.name > b.name) ? 1 : -1)
            .map((fund) => (
              <div key={fund.id} style={{ marginBottom: '2px' }}>
                <p style={styles.smallParagraph}>
                  <strong>{fund.name}</strong> - ₱ {formatAmountWithCommas(parseFloat(fund.amount).toFixed(2))}
                </p>
              </div>
            ))}
        </div>


        <div style={styles.smallCard} >
          <h3 style={styles.smallHeading}>Disbursed Amount </h3>
          {getReleasedFunds().sort().map((fundName) => (
            <div key={fundName} style={{ marginBottom: '2px' }}>
              <p style={styles.smallParagraph}>
                <strong>{fundName}</strong> - ₱ {formatAmountWithCommas(parseFloat(totalReleasedAmountByFund[fundName]).toFixed(2))}
              </p>
            </div>
          ))}
        </div>


        <div style={styles.smallCard}>
          <h3 style={styles.smallHeading}>Liquidated Amount</h3>
          {getLiquidatedFunds().sort().map((fundName) => (
            <div key={fundName} style={{ marginBottom: '2px' }}>
              <p style={styles.smallParagraph}>
                <strong>{fundName}</strong> - ₱ {formatAmountWithCommas(parseFloat(totalLiquidatedAmountByFund[fundName]).toFixed(2))}
              </p>
            </div>
          ))}
        </div>

      </div>

      <div style={styles.flexContainer}>
        <div style={{ ...styles.bigCard, height: '55vh', width: '99%', flex: 'none' }}>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <h2 style={styles.smallHeading}>Overview</h2>
          </div>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={getStartingfund().map((fundName) => ({
                name: fundName,
                StartingFund: fundLog.find((fund) => fund.fund_name === fundName)?.amount || 0,
                RemainingFund: fund_per_business.find((fund) => fund.name === fundName)?.amount || 0,
                Disbursed: totalReleasedAmountByFund[fundName] || 0,
                Liquidated: totalLiquidatedAmountByFund[fundName] || 0,
              }))}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip labelStyle={{ color: '#fff' }} />
              <Legend />
              <Bar dataKey="StartingFund" name="Starting Fund" fill="#0C356A" />
              <Bar dataKey="RemainingFund" name="Remaining Fund" fill="#E74C3C" />
              <Bar dataKey="Disbursed" name="Disbursed" fill="#3D3D3D" />
              <Bar dataKey="Liquidated" name="Liquidated" fill="#00A9B5" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>


    </div>

  );
};

const blue_card = '#fff';

const styles = {
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '20px',
    flexWrap: 'wrap', // Allow flex items to wrap
  },
  smallCard: {
    flex: '1 1 200px', // Flex-grow, Flex-shrink, Flex-basis
    maxWidth: '400px',
    padding: '10px',
    borderRadius: '8px',
    backgroundColor: blue_card,
    margin: '10px',
    border: '1px solid #ccc',
    minHeight: '300px', // Set minimum height for the card
  },
  bigCard: {
    flex: '1 0 auto', // Flex-grow, Flex-shrink, Flex-basis
    borderRadius: '8px',
    backgroundColor: blue_card,
    padding: '10px',
    margin: '10px',
    border: '1px solid #ccc',
  },
  smallHeading: {
    textAlign: 'center',
    color: '#000',
    marginBottom: '2px',
    position: 'sticky',
    top: '0',
    backgroundColor: blue_card,
    zIndex: '1',
  },
  smallParagraph: {
    marginLeft: '10px',
    marginBottom: '2px',
    color: '#000',
    fontSize: '1rem',
    overflowY: 'auto',
    maxHeight: '100%',
  },
};


export default Home;

import React, { useState, useEffect } from 'react';
import AxiosInstance from '../Axios';
import { createFundAllocation, editFundAllocation, deleteFundAllocation } from '../services/FundAllocationSystem';
import { useAuth } from '../services/AuthContext';
import Toast from '../services/ToastService';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Loader from '../services/Loader';
const FundAllocation = () => {
  const { isAuthenticated, currentUser } = useAuth();
  const [fundAllocation, setFundAllocation] = useState([]);
  const [editedFundAllocation, setEditedFundAllocation] = useState(null);
  const [business, setbusinessUnit] = useState([]);
  const [fund, setFundName] = useState([]);
  const [newFundAllocation, setNewFundAllocation] = useState({
    name: '',
    business_unit: '',
    amount: '',
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {


    const fetchFundAllocation = async () => {
      try {
        const response = await AxiosInstance.get(`/api/AllocationListView/`);
        setFundAllocation(response.data);

        const businessResponse = await AxiosInstance.get('/api/Fund_Comapny_View/');
        setbusinessUnit(businessResponse.data);

        const fundResponse = await AxiosInstance.get('/api/FundListView/');
        setFundName(fundResponse.data);

        const updatedResponse = await AxiosInstance.get('/api/FundListView/');
        setFundName(updatedResponse.data);

        setLoading(false);
      } catch (error) {
        Toast({ message: 'Error fetching data from server.', type: 'error' });
        setLoading(false);
      }
    };

    fetchFundAllocation();
  }, [currentUser, isAuthenticated]);


  if (loading) {
    return <Loader />;
  }


  const handleEdit = (fundAllocationId) => {
    const fundAllocationToEdit = fundAllocation.find((item) => item.id === fundAllocationId);
    setEditedFundAllocation(fundAllocationToEdit);
  };

  const handleClose = () => {
    setEditedFundAllocation(false);
  };



  const handleCreateFundAllocation = async () => {
    try {
      const totalNewAmount = parseFloat(newFundAllocation.amount || 0, 10);
      if (totalNewAmount > parseFloat(fund[0]?.amount || 0, 10)) {
        Toast({ message: 'Amount is higher than the available amount.', type: 'error' });
        return;
      }

      await createFundAllocation(newFundAllocation);

      Toast({ message: 'Fund Allocation created successfully.', type: 'success' });
      const updatedResponse = await AxiosInstance.get('/api/AllocationListView/');
      setFundAllocation(updatedResponse.data);
      setNewFundAllocation({
        amount: '',
        business_unit: '',

      });
      const updateResponse = await AxiosInstance.get('/api/FundListView/');
      setFundName(updateResponse.data);
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.message === 'Company Name already existed in this fund.') {
        Toast({ message: error.response.data.message, type: 'error' });
      } else {
        Toast({ message: 'Error creating fund allocation.', type: 'error' });
      }
    }
  };

  const handleEditFundAllocation = async () => {
    try {
      if (editedFundAllocation) {
        await editFundAllocation(editedFundAllocation.id, editedFundAllocation);
        setEditedFundAllocation(null);

        Toast({ message: 'Fund Allocation edited successfully.', type: 'success' });
        const updatedResponse = await AxiosInstance.get('/api/AllocationListView/');
        setFundAllocation(updatedResponse.data);
        const updateResponse = await AxiosInstance.get('/api/FundListView/');
        setFundName(updateResponse.data);
      } else {
        Toast({ message: 'Fund allocation not Found.', type: 'error' });
      }
    } catch (error) {
      Toast({ message: 'Error editing Fund allocation.', type: 'error' });
    }
  };

  const handleDeleteFundAllocation = async (fundAllocationId) => {
    try {
      const isConfirmed = window.confirm('Are you sure you want to delete this Allocated Fund?');
      if (isConfirmed) {
        await deleteFundAllocation(fundAllocationId);
        const updatedResponse = await AxiosInstance.get('/api/AllocationListView/');
        setFundAllocation(updatedResponse.data);
        const updateResponse = await AxiosInstance.get('/api/FundListView/');
        setFundName(updateResponse.data);

        Toast({ message: 'Fund allocation deleted successfully.', type: 'success' });
      } else {
        Toast({ message: 'Fund allocation deletion cancelled.', type: 'success' });
      }
    } catch (error) {
      Toast({ message: 'Error deleting Fund allocation.', type: 'success' });
    }
  };

  const theme = createTheme({
    typography: {
      fontFamily: 'Montserrat, sans-serif',
    },
  });

  function formatAmountWithCommas(amount) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  //Css style
  const formContainerStyle = {
    marginBottom: '10px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    maxWidth: '100%',
    margin: 'auto',
    background: '#f9fafb',
  };

  const flexContainerStyle = {
    display: 'flex',
    marginBottom: '8px',
  };

  const flexItemStyle = {
    flex: 1,
    marginRight: '8px',
  };

  const inputStyle = {
    width: '100%',
    padding: '8px',
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
  };


  const buttonStyle = {
    background: '#52ab98',
    color: 'white',
    border: 'none',
    padding: '8px 12px',
    cursor: 'pointer',
    borderRadius: '4px',
  };

  return (
    <div>
      <ThemeProvider theme={theme}>

        <div style={formContainerStyle}>
          <h4>
            <label htmlFor="RemainingFund">Available Fund:</label>
            {fund.map((fundItem) => (
              <label key={fundItem.id} style={{ display: 'block', marginBottom: '8px', fontWeight: 'normal' }}>
                {fundItem.name} : ₱ {formatAmountWithCommas(fundItem.amount)}
              </label>
            ))}
          </h4>
          {parseFloat(fund[0]?.amount || 0, 10) < parseFloat(newFundAllocation.amount || 0, 10) && (
            <p style={{ color: 'red' }}>Amount is higher than the available amount</p>
          )}
        </div>

        <h2>Allocate Fund</h2>
        <form style={formContainerStyle}>
          <div style={flexContainerStyle}>

            <div style={{ ...flexItemStyle }}>
              <label htmlFor="fund_Name">Fund:</label>
              <select
                id="fund_Name"
                value={newFundAllocation.name || ''}
                onChange={(e) => setNewFundAllocation({ ...newFundAllocation, name: e.target.value })}
                style={inputStyle}
              >
                <option value="" disabled>Select Fund</option>
                {fund.map((name) => (
                  <option key={name.id} value={name.id}>
                    {name.name} : {name.amount}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ ...flexItemStyle }}>
              <label htmlFor="business_Unit">Company Name:</label>
              <select
                id="business_Unit"
                value={newFundAllocation.business_unit || ''}
                onChange={(e) => {
                  setNewFundAllocation({ ...newFundAllocation, business_unit: e.target.value });
                }}
                style={inputStyle}
              >
                <option value="" disabled>Select Company Name</option>
                {business.map((fund) =>
                  fund.business_units.map((businessUnitId, index) => {
                    const uniqueKey = `${fund.id}-${index}`;
                    const businessName = fund.business_name[index];
                    return (
                      <option key={uniqueKey} value={businessUnitId}>
                        {businessName}
                      </option>
                    );
                  })
                )}
              </select>
            </div>

            <div style={{ ...flexItemStyle }}>
              <label htmlFor="createAmount">Amount:</label>
              <input
                type="text"
                style={{ ...inputStyle, textAlign: 'right' }}
                id="createAmount"
                value={newFundAllocation.amount}
                onChange={(e) => setNewFundAllocation({ ...newFundAllocation, amount: e.target.value })}
              />
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button type="button" style={buttonStyle} onClick={handleCreateFundAllocation}>
              Create
            </button>
          </div>
        </form>

        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid #ddd', background: '#f2f2f2' }}>
              <th>No</th>
              <th>Fund</th>
              <th>Company Name</th>
              <th>Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {fundAllocation
              .slice()
              .sort((a, b) => a.business_name.localeCompare(b.business_name))
              .map((fundAllocation, index) => (
                <tr key={fundAllocation.id} style={{ borderBottom: '1px solid #ddd' }}>
                  <td style={{ textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ textAlign: 'center' }}>{fundAllocation.fund_name}</td>
                  <td style={{ textAlign: 'center' }}>{fundAllocation.business_name}</td>
                  <td style={{ textAlign: 'center' }}> ₱ {formatAmountWithCommas(fundAllocation.amount)}</td>
                  <td style={{ textAlign: 'center' }}>
                    <button type="button"
                      style={{ marginRight: '8px', background: '#52ab98', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px' }}
                      onClick={() => handleEdit(fundAllocation.id)}>
                      Edit
                    </button>
                    <button type="button"
                      style={{ background: '#f44336', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px' }}
                      onClick={() => handleDeleteFundAllocation(fundAllocation.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        {/* Form for editing fund allocation */}
        {editedFundAllocation && (
          <div
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              background: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                background: '#fff',
                padding: '16px',
                borderRadius: '4px',
                width: '50%',
                overflow: 'auto',
                maxHeight: '80%',
              }}
            >
              <div>
                <form>
                  <h3>Edit Fund Allocation</h3>
                  <div style={flexContainerStyle}>
                    <div style={{ ...flexItemStyle }}>
                      <label htmlFor="editFundName">Fund:</label>
                      <input
                        style={inputStyle}
                        type="text"
                        id="editFundName"
                        value={editedFundAllocation.fund_name}
                        onChange={(e) => setEditedFundAllocation({ ...editedFundAllocation, fund_name: e.target.value })}
                      />
                    </div>

                    <div style={{ ...flexItemStyle }}>
                      <label htmlFor="editBusinessName">Company Name:</label>
                      <input
                        style={inputStyle}
                        type="text"
                        id="editBusinessName"
                        value={editedFundAllocation.business_name}
                        onChange={(e) => setEditedFundAllocation({ ...editedFundAllocation, business_name: e.target.value })}
                      />
                    </div>

                    <div style={{ ...flexItemStyle }}>
                      <label htmlFor="editAmount">Amount:</label>
                      <input
                        style={{ ...inputStyle, textAlign: 'right' }}
                        type="text"
                        id="editAmount"
                        value={editedFundAllocation.amount}
                        onChange={(e) => setEditedFundAllocation({ ...editedFundAllocation, amount: e.target.value })}
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', marginRight: '5px' }}>
                    <button type="button" style={{ ...buttonStyle, marginRight: '5px' }} onClick={handleEditFundAllocation}>
                      Save
                    </button>
                    <button style={{ background: '#9FA6B2', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', marginRight: '5px' }} onClick={handleClose}>Close</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </ThemeProvider>
    </div>
  );
};

export default FundAllocation;

import React, { useState, useEffect } from 'react';
import AxiosInstance from '../Axios';
import { createFund, editFund, deleteFund } from '../services/FundService';
import Toast from '../services/ToastService';
import Loader from '../services/Loader';
import { ToastContainer, } from 'react-toastify';
const Fund = () => {
  const [fund, setsFund] = useState([]);
  const [users, setUsers] = useState([]);
  const [EditedFund, setEditedFund] = useState(null);
  const [newFund, setNewFund] = useState({
    name: '',
    amount: '',
    user: '',
  });
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    const fetchfund = async () => {
      try {
        const response = await AxiosInstance.get('/api/fund_list/');
        setsFund(response.data);

        const userResponse = await AxiosInstance.get('/api/custodian_users/');
        setUsers(userResponse.data);
        setLoading(false);
      } catch (error) {
        Toast({ message: 'Error fetching data', type: 'error' });
        setLoading(false);
      }
    };

    fetchfund();
  }, []);

  if (loading) {
    return <Loader />;
  }



  const handleCreateFund = async () => {
    try {

      const fundWithNameExists = fund.some((existingFund) => existingFund.name === newFund.name);
      const userHasFund = fund.some((existingFund) => existingFund.user === newFund.user);

      if (fundWithNameExists) {
        Toast({ message: 'Fund with the same name already exists.', type: 'error' });
        return;
      }

      if (userHasFund) {
        Toast({ message: 'The User already has a fund.', type: 'error' });
        return;
      }
      await createFund(newFund);

      const updatedResponse = await AxiosInstance.get('/api/fund_list/');
      setsFund(updatedResponse.data);

      setNewFund({
        name: '',
        amount: '',
        user: '',
      });

      Toast({ message: 'Fund created successfully.', type: 'success' });
    } catch (error) {
      Toast({ message: 'Error creating Fund.', type: 'error' });
      if (error.response) {
        Toast({ message: 'Server response data.', type: 'error' });
      }
    }
  };

  const handleDelete = async (fundId) => {
    try {
      const isConfirmed = window.confirm('Are you sure you want to delete this Fund?');
      if (isConfirmed) {
        await deleteFund(fundId);
        Toast({ message: 'Fund deleted successfully.', type: 'success' });
        const updatedResponse = await AxiosInstance.get('/api/fund_list/');
        setUsers(updatedResponse.data);
        const fundResponse = await AxiosInstance.get('/api/fund_list/');
        setsFund(fundResponse.data);
      } else {
        Toast({ message: 'Fund deletion cancelled.', type: 'success' });
      }
    } catch (error) {
      Toast({ message: 'Error deleting fund.', type: 'error' });
    }
  };


  const handleEditFund = async () => {
    try {
      if (EditedFund) {

        await editFund(EditedFund.id, EditedFund);
        Toast({ message: 'Fund edited successfully.', type: 'success' });
        setEditedFund(null);
        const updatedResponse = await AxiosInstance.get('/api/fund_list/');
        setUsers(updatedResponse.data);
        const fundResponse = await AxiosInstance.get('/api/fund_list/');
        setsFund(fundResponse.data);
      } else {
        Toast({ message: 'Fund not Found.', type: 'error' });
      }
    } catch (error) {
      Toast({ message: 'Error editing Fund.', type: 'error' });
      if (error.response) {
        Toast({ message: 'Server response data.', type: 'error' });
      }
    }
  };

  const handleEdit = (fundId) => {
    const fundToEdit = fund.find((fundItem) => fundItem.id === fundId);
    setEditedFund(fundToEdit);
  };

  const handleClose = () => {
    setEditedFund(false);
  };

  function formatAmountWithCommas(amount) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }



  //Css style
  const formContainerStyle = {
    marginBottom: '10px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    maxWidth: '100%',
    margin: 'auto',
    background: '#f9fafb',
  };

  const flexContainerStyle = {
    display: 'flex',
    marginBottom: '8px',
  };

  const flexItemStyle = {
    flex: 1,
    marginRight: '8px',
  };

  const inputStyle = {
    width: '100%',
    padding: '8px',
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
  };


  const buttonStyle = {
    background: '#52ab98',
    color: 'white',
    border: 'none',
    padding: '8px 12px',
    cursor: 'pointer',
    borderRadius: '4px',
  };



  return (
    <div>

      {/* Create Fund */}
      <h2>Create Fund</h2>
      <form style={formContainerStyle}>
        <div style={flexContainerStyle}>
          <div style={{ ...flexItemStyle }}>
            <label htmlFor="createName">Fund Name:</label>
            <input
              type="text"
              id="createName"
              value={newFund.name}
              onChange={(e) => setNewFund({ ...newFund, name: e.target.value })}
              style={inputStyle}
            />
          </div>

          <div style={{ ...flexItemStyle }}>
            <label htmlFor="createAmount">Amount:</label>
            <input
              type="text"
              id="createAmount"
              value={newFund.amount}
              onChange={(e) => setNewFund({ ...newFund, amount: e.target.value })}
              style={inputStyle}
            />
          </div>

          <div style={{ ...flexItemStyle }}>
            <label htmlFor="createUser">User:</label>
            <select
              id="createUser"
              value={newFund.user}
              onChange={(e) => setNewFund({ ...newFund, user: e.target.value })}
              style={inputStyle}
            >
              <option value="" disabled>Select User</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.first_name} {user.last_name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button style={buttonStyle} type="button" onClick={handleCreateFund}>
            Create
          </button>
        </div>
      </form>
      <br />

      {/* Fund List */}
      <div style={formContainerStyle}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid #ddd', background: '#f2f2f2' }}>
              <th>No</th>
              <th>Fund Name</th>
              <th>Amount</th>
              <th>Person Incharge</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {fund
              .slice()
              .sort((a, b) => (a.name > b.name) ? 1 : -1)
              .map((fund, index) => (
                <tr key={fund.id} style={{ borderBottom: '1px solid #ddd' }}>
                  <td style={{ textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ textAlign: 'center' }}>{fund.name}</td>
                  <td style={{ textAlign: 'center' }}>₱ {formatAmountWithCommas(fund.amount)}</td>
                  <td style={{ textAlign: 'center' }}>{fund.first_name} {fund.last_name}</td>
                  <td style={{ textAlign: 'center' }}>
                    <button type="button"
                      style={{ marginRight: '8px', background: '#52ab98', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px' }}
                      onClick={() => handleEdit(fund.id)}>
                      Edit
                    </button>
                    <button type="button"
                      style={{ background: '#f44336', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px' }}
                      onClick={() => handleDelete(fund.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {EditedFund && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: '#fff',
              padding: '16px',
              borderRadius: '4px',
              width: '50%',
              overflow: 'auto',
              maxHeight: '80%',
            }}
          >
            <div>
              <form >
                <h2>Edit Fund</h2>
                <div style={flexContainerStyle}>
                  <div style={{ ...flexItemStyle }}>
                    <label htmlFor="fundName">Fund Name:</label>
                    <input
                      style={inputStyle}
                      type="text"
                      id="fundName"
                      value={EditedFund.name}
                      onChange={(e) =>
                        setEditedFund({ ...EditedFund, name: e.target.value })
                      }
                    />
                  </div>

                  <div style={{ ...flexItemStyle }}>
                    <label htmlFor="amount">Amount:</label>
                    <input
                      style={inputStyle}
                      type="text"
                      id="amount"
                      value={EditedFund.amount}
                      onChange={(e) =>
                        setEditedFund({ ...EditedFund, amount: e.target.value })
                      }
                    />
                  </div>

                  <div style={{ ...flexItemStyle }}>
                    <label htmlFor="personIncharge">Person Incharge:</label>
                    <select
                      id="personIncharge"
                      value={EditedFund.user}
                      onChange={(e) => setEditedFund({ ...EditedFund, user: e.target.value })}
                      style={inputStyle}
                    >
                      <option value="" disabled>Select User</option>
                      {users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.first_name} {user.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', marginRight: '5px' }}>
                  <button type="button" style={{ ...buttonStyle, marginRight: '5px' }} onClick={handleEditFund}>
                    Save
                  </button>
                  <button style={{ background: '#9FA6B2', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', marginRight: '5px' }} onClick={handleClose}>Close</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />

    </div>
  );
};

export default Fund;

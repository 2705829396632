import React, { useState, useEffect } from 'react';
import AxiosInstance from '../Axios';
import { useAuth } from '../services/AuthContext';
import Toast from '../services/ToastService';
import { useNavigate } from 'react-router-dom';
import Loader from '../services/Loader';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const GeneralManagerApproved = () => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [PurchaseRequest, setPurchaseRequest] = useState([]);
    const [selectedPurchaseRequest, setSelectedPurchaseRequest] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);
    const [fundName, setFundName] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {


                const response = await AxiosInstance.get('/api/PurchaseRequestApprovedListView/');
                setPurchaseRequest(response.data);

                const fundResponse = await AxiosInstance.get('/api/Allocation_List_Per_BU_View/');
                setFundName(fundResponse.data);

                setLoading(false);
            } catch (error) {
                Toast({ message: 'Error fetching data from server.', type: 'error' });
                setLoading(false);
            }
        };

        fetchData();
    }, [currentUser]);

    if (loading) {
        return <Loader />;
    }

    const handleViewDetail = (purchaseRequest) => {
        setSelectedPurchaseRequest(purchaseRequest);
    };

    const handleClosePopup = () => {
        setSelectedPurchaseRequest(null);
    };

    const navigateToDashboard = () => {
        navigate('/Dashboard');
    };

    //Css style
    const formContainerStyle = {
        marginBottom: '10px',
        padding: '10px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        maxWidth: '100%',
        margin: 'auto',
        background: '#f9fafb',
    };

    function formatDate(dateString) {
        const dateParts = dateString.split("-");
        const formattedDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        return formattedDate.toLocaleDateString("en-US");
    }

    function formatAmountWithCommas(amount) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    const generatePDF = () => {
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: [205, 210]
        });
        pdf.setFont('times', 'normal');
        pdf.text(` ${selectedPurchaseRequest.business_unit_name} Fund Request Details`, 70, 10);
        pdf.setFontSize(8);
        pdf.setFontSize(10);

        pdf.text(`Voucher No: ${selectedPurchaseRequest.voucher_no}`, 20, 30);
        pdf.text(`Activity: ${selectedPurchaseRequest.activity}`, 20, 35);
        pdf.text(`Profit Center: ${selectedPurchaseRequest.profit_center}`, 20, 40);
        pdf.text(`Covered Date: ${formatDate(selectedPurchaseRequest.covered_from)} - ${formatDate(selectedPurchaseRequest.covered_to)}`, 20, 45);
        pdf.text(`Status: ${selectedPurchaseRequest.status}`, 20, 50);

        const dateRequestedText = `Date Requested: ${formatDate(selectedPurchaseRequest.date_requested)}`;
        const dateRequestedWidth = pdf.getTextDimensions(dateRequestedText).w;
        const dateRequestedX = pdf.internal.pageSize.width - 20 - dateRequestedWidth;
        pdf.text(dateRequestedText, dateRequestedX, 30);

        const items = selectedPurchaseRequest.items;
        const headers = ['Description', 'Quantity', 'Unit of Measure', 'Price', 'Amount'];
        const data = items.map(item => [item.descriptions, item.quantity, item.uom, formatAmountWithCommas(item.price), formatAmountWithCommas(item.item_total_amount)]);
        const totalAmountRow = ['', '', '', 'Total:', `${formatAmountWithCommas(selectedPurchaseRequest.amount)}`];
        data.push(totalAmountRow);

        let startY = 55;

        pdf.autoTable({
            head: [headers],
            body: data,
            startY: startY,
            styles: {
                fontSize: 8
            },
            didDrawPage: function (data) {
                startY = data.cursor.y + 10;
            }
        });

        pdf.text(`Requested By:`, 20, startY);
        pdf.text(`Date:`, 20, startY + 10);
        pdf.text(`${selectedPurchaseRequest.requested_by} `, 20, startY + 5);
        pdf.text(`${formatDate(selectedPurchaseRequest.encoded_date)} `, 30, startY + 10);

        pdf.text(`Reviewed By:`, 90, startY);
        pdf.text(`Date:`, 90, startY + 10);
        pdf.text(`${selectedPurchaseRequest.reviewer_name}  ${selectedPurchaseRequest.reviewer_last}`, 90, startY + 5);
        pdf.text(`${formatDate(selectedPurchaseRequest.reviewed_date)} `, 100, startY + 10);

        pdf.text(`Approved By:`, 150, startY);
        pdf.text(`Date:`, 150, startY + 10);
        pdf.text(`${selectedPurchaseRequest.approved}  ${selectedPurchaseRequest.approved_last}`, 150, startY + 5);
        pdf.text(`${formatDate(selectedPurchaseRequest.approved_date)} `, 160, startY + 10);

        pdf.setFontSize(8);
        pdf.output('dataurlnewwindow');
    };

    const calculatePendingCount = (businessUnitName) => {
        const pendingItems = PurchaseRequest.filter(request => request.business_unit_name === businessUnitName && request.status === 'Approved');
        return pendingItems.length;
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };



    return (
        <div>

            <div>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    {fundName.map((buData, index) => (
                        <Tab key={index} label={<div style={{ position: 'relative' }}>
                            {buData.business_unit_name}
                            <div style={{ position: 'absolute', top: '-10px', right: '-15px', color: 'red', borderRadius: '50%', padding: '4px', fontSize: '12px', fontWeight: 'bold' }}>
                                {calculatePendingCount(buData.business_unit_name)}
                            </div>
                        </div>} />
                    ))}
                </Tabs>
            </div>

            {fundName.map((buData, index) => (
                <div key={index} hidden={selectedTab !== index}>
                    <div style={formContainerStyle}>
                        <h4>
                            <label htmlFor="RemainingFund">Approved Funds</label>
                        </h4>
                        <div style={{ overflowX: 'auto' }}>
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <thead>
                                    <tr style={{ borderBottom: '1px solid #ddd', background: '#f2f2f2' }}>
                                        <th style={{ padding: '6px' }}>Voucher No</th>
                                        <th style={{ padding: '6px' }}>Date Requested</th>
                                        <th style={{ padding: '6px' }}>Reviewed By</th>
                                        <th style={{ padding: '6px' }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {PurchaseRequest
                                        .filter(request => request.business_unit_name === buData.business_unit_name)
                                        .sort((a, b) => (a.voucher_no > b.voucher_no) ? 1 : -1)
                                        .map((purchaseRequest, idx) => (
                                            <tr key={purchaseRequest.id} style={{ borderBottom: '1px solid #ddd', background: '#f2f2f2' }}>
                                                <td style={{ textAlign: 'center', padding: '6px' }}>{purchaseRequest.voucher_no}</td>
                                                <td style={{ textAlign: 'center', padding: '6px' }}>{formatDate(purchaseRequest.date_requested)}</td>
                                                <td style={{ textAlign: 'center', padding: '6px' }}>{purchaseRequest.reviewer_name} {purchaseRequest.reviewer_last}</td>
                                                <td style={{ textAlign: 'center', padding: '6px' }}>
                                                    <button
                                                        style={{
                                                            padding: '6px',
                                                            cursor: 'pointer',
                                                            backgroundColor: '#52ab98',
                                                            color: '#ffffff',
                                                            borderRadius: '4px',
                                                            border: '1px solid #52ab98',
                                                        }}
                                                        onClick={() => handleViewDetail(purchaseRequest)}
                                                    >
                                                        View Detail
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            ))}

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', marginRight: '5px' }}>
                <button
                    style={{ background: '#9FA6B2', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', marginRight: '5px' }}
                    onClick={navigateToDashboard}
                >
                    Back
                </button>
            </div>

            {selectedPurchaseRequest && (
                <div
                    style={{
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            background: '#fff',
                            padding: '16px',
                            borderRadius: '4px',
                            width: '70%',
                            overflow: 'auto',
                            maxHeight: '80%',
                        }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '5px', marginRight: '5px' }}>
                            <IconButton
                                color="gray"
                                onClick={generatePDF}
                                sx={{ borderRadius: '4px', padding: '8px' }}
                            >
                                <PrintIcon />
                            </IconButton>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <strong>Voucher No:</strong> {selectedPurchaseRequest.voucher_no}
                            </div>
                            <div>
                                <strong>Date Requested:</strong> {formatDate(selectedPurchaseRequest.date_requested)}
                            </div>
                        </div>
                        <div>
                            <strong>Covered Date:</strong> {formatDate(selectedPurchaseRequest.covered_from)} to {formatDate(selectedPurchaseRequest.covered_to)}
                        </div>
                        <div>
                            <strong>Activity:</strong> {selectedPurchaseRequest.activity}
                        </div>
                        <div>
                            <strong>Profit Center:</strong> {selectedPurchaseRequest.profit_center}
                        </div>
                        <div>
                            <strong>Company Name:</strong> {selectedPurchaseRequest.business_unit_name}
                        </div>
                        <div>
                            <strong>Status:</strong> {selectedPurchaseRequest.status}
                        </div>
                        <div>
                            <strong>Items:</strong>
                        </div>
                        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                            <thead>
                                <tr style={{ borderBottom: '1px solid #ddd', background: '#f2f2f2' }}>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                    <th>Unit of Measure</th>
                                    <th>Price</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedPurchaseRequest.items.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ textAlign: 'center' }}>{item.descriptions}</td>
                                        <td style={{ textAlign: 'center' }}>{item.quantity}</td>
                                        <td style={{ textAlign: 'center' }}>{item.uom}</td>
                                        <td style={{ textAlign: 'center' }}>{formatAmountWithCommas(item.price)}</td>
                                        <td style={{ textAlign: 'center' }}>{formatAmountWithCommas(item.item_total_amount)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginRight: '40px' }}>
                            <div>
                                <strong>Total Amount:</strong> {formatAmountWithCommas(selectedPurchaseRequest.amount)}
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginRight: '20px' }}>
                            <div><strong>Requested By:</strong> {selectedPurchaseRequest.requested_by}</div>
                            <div><strong>Reviewed By:</strong> {selectedPurchaseRequest.reviewer_name} {selectedPurchaseRequest.reviewer_last}</div>
                            <div style={{ marginRight: '10px' }}><strong>Approved By:</strong> {selectedPurchaseRequest.approved} {selectedPurchaseRequest.approved_last}</div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '40px' }}>
                            <div><strong>Date:</strong> {formatDate(selectedPurchaseRequest.encoded_date)} </div>
                            <div><strong>Date:</strong> {formatDate(selectedPurchaseRequest.reviewed_date)} </div>
                            <div><strong>Date:</strong> {formatDate(selectedPurchaseRequest.approved_date)} </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', marginRight: '5px' }}>
                            <button style={{ background: '#9FA6B2', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', marginRight: '5px' }} onClick={handleClosePopup}>Close</button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};


export default GeneralManagerApproved;

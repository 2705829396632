import React, { useState, useEffect } from 'react';
import AxiosInstance from '../Axios';
import { deleteUser, editUser, createUser } from '../services/UserService';
import Toast from '../services/ToastService';
import { ToastContainer, } from 'react-toastify';
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
const UserList = () => {
  const [users, setUsers] = useState([]);
  const [editedUser, setEditedUser] = useState();
  const [business, setbusinessUnit] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [newUser, setNewUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    role: 'Cost Controller',
    business_unit: [],
  });


  const userRoles = [
    ['Cost Controller', 'Cost Controller'],
    ['Finance Manager', 'Finance Manager'],
    ['Fund Custodian', 'Fund Custodian'],
    ['Admin', 'Admin'],
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AxiosInstance.get('/api/user_list/');
        setUsers(response.data);

        const businessResponse = await AxiosInstance.get('/api/businessUnit_list/');
        setbusinessUnit(businessResponse.data);

      } catch (error) {
        Toast({ message: 'Error fetching data', type: 'error' });

      }

    };

    fetchData();
  }, []);


  const handleEdit = (userId) => {
    const userToEdit = users.find((user) => user.id === userId);
    if (!userToEdit.password) userToEdit.password = '';
    setEditedUser(userToEdit);
  };

  const handleClose = () => {
    setEditedUser(false);
  };


  const handleCreateUser = async () => {
    try {

      if (newUser.password.length < 8) {
        Toast({ message: 'Password must be 8 characters long or longer.', type: 'error' });
        return;
      }

      if (!newUser.role) {
        Toast({ message: 'Role must not be empty.', type: 'error' });
        return;
      }

      const userWithEmailExists = users.some((existingUser) => existingUser.email === newUser.email);

      if (userWithEmailExists) {
        Toast({ message: 'User with the same email already exists.', type: 'error' });
        return;
      }

      await createUser(newUser);
      const updatedResponse = await AxiosInstance.get('/api/user_list/');

      setUsers(updatedResponse.data);
      Toast({ message: 'User created successfully.', type: 'success' });
      setNewUser({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        role: 'Encoder',
        business_unit: [],

      });
    } catch (error) {
      Toast({ message: 'Error creating user.', type: 'error' });
      if (error.response) {
        Toast({ message: 'Server error.', type: 'error' });
      }
    }
  };

  const handleSaveEdit = async () => {
    try {
      if (editedUser) {
        const updatedUser = { ...editedUser };

        if (updatedUser.password === '') {
          delete updatedUser.password;
        }

        await editUser(editedUser.id, updatedUser);
        Toast({ message: 'User edited successfully.', type: 'success' });
        setEditedUser(null);
        const updatedResponse = await AxiosInstance.get('/api/user_list/');
        setUsers(updatedResponse.data);
      } else {
        Toast({ message: 'User not Found.', type: 'error' });
      }
    } catch (error) {
      Toast({ message: 'Error editing User.', type: 'error' });
      if (error.response) {
        Toast({ message: 'Server error.', type: 'error' });
      }
    }
  };

  const handleDelete = async (userId) => {
    try {
      const isConfirmed = window.confirm('Are you sure you want to delete this User?');

      if (isConfirmed) {
        await deleteUser(userId);
        Toast({ message: 'User deleted successfully.', type: 'success' });
        const updatedResponse = await AxiosInstance.get('/api/user_list/');
        setUsers(updatedResponse.data);
      } else {
        Toast({ message: 'User deletion cancelled.', type: 'success' });
      }
    } catch (error) {
      Toast({ message: 'Error deleting user.', type: 'success' });
    }
  };

  const filteredUsers = users.filter(user => selectedRole === '' || user.role === selectedRole);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(users.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }


  const handleRoleFilterChange = (event) => {
    setSelectedRole(event.target.value);
  };

  //Css style
  const formContainerStyle = {
    marginBottom: '10px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    maxWidth: '100%',
    margin: 'auto',
    background: '#f9fafb',
  };

  const flexContainerStyle = {
    display: 'flex',
    marginBottom: '8px',
  };

  const flexItemStyle = {
    flex: 1,
    marginRight: '8px',
  };

  const inputStyle = {
    width: '100%',
    padding: '8px',
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
  };


  const buttonStyle = {
    background: '#52ab98',
    color: 'white',
    border: 'none',
    padding: '8px 12px',
    cursor: 'pointer',
    borderRadius: '4px',
  };



  return (
    <div>
      <h2>Create User</h2>
      <form style={formContainerStyle}>
        <div style={flexContainerStyle}>
          <div style={{ ...flexItemStyle }}>
            <label htmlFor="createFirstName">First Name:</label>
            <input
              style={inputStyle}
              type="text"
              id="createFirstName"
              value={newUser.first_name}
              onChange={(e) => setNewUser({ ...newUser, first_name: e.target.value })}
            />
          </div>

          <div style={{ ...flexItemStyle }}>
            <label htmlFor="createLastName">Last Name:</label>
            <input
              style={inputStyle}
              type="text"
              id="createLastName"
              value={newUser.last_name}
              onChange={(e) => setNewUser({ ...newUser, last_name: e.target.value })}
            />
          </div>

          <div style={{ ...flexItemStyle }}>
            <label htmlFor="createEmail">Email:</label>
            <input
              style={inputStyle}
              type="email"
              id="createEmail"
              value={newUser.email}
              onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            />
          </div>

          <div style={{ ...flexItemStyle }}>
            <label htmlFor="createPassword">Password:</label>
            <input
              style={{ ...inputStyle, width: 'calc(100% - 40px)' }}
              type={showPassword ? 'text' : 'password'}
              id="createPassword"
              value={newUser.password}
              onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
            />
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </div>

          <div style={{ ...flexItemStyle }}>
            <label htmlFor="business_unit">Company Name:</label>
            <select
              id="business_unit"
              value={newUser.business_unit || []}
              onChange={(e) => {
                const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
                setNewUser({ ...newUser, business_unit: selectedOptions });
              }}
              style={inputStyle}
              multiple
            >
              <option value="" disabled>Select Company Name</option>
              {business.map((business_unit) => (
                <option key={business_unit.id} value={business_unit.id}>
                  {business_unit.business_unit_name}
                </option>
              ))}
            </select>
          </div>

          <div style={{ ...flexItemStyle }}>
            <label htmlFor="createRole">Role:</label>
            <select
              style={inputStyle}
              id="createRole"
              value={newUser.role}
              onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
            >
              {userRoles.map((role) => (
                <option key={role[0]} value={role[0]}>
                  {role[1]}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button style={buttonStyle} type="button" onClick={handleCreateUser}>
            Create
          </button>
        </div>
      </form>
      <br />

      <div style={formContainerStyle}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '5px' }}>
          <select value={selectedRole} onChange={handleRoleFilterChange} style={{ borderRadius: '8px', border: '1px solid #ccc', padding: '5px', marginRight: '50px', }}>
            <option value="">All Roles</option>
            <option value="Cost Controller">Cost Controller</option>
            <option value="Finance Manager">Finance Manager</option>
            <option value="Fund Custodian">Fund Custodian</option>
          </select>
        </div>

        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid #ddd', background: '#f2f2f2' }}>
              <th>No</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Company Name</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentItems
              .filter(user => selectedRole === '' || user.role === selectedRole)
              .slice()
              .sort((a, b) => (a.first_name > b.first_name) ? 1 : -1)
              .map((user, index) => (
                <tr key={user.id} style={{ borderBottom: '1px solid #ddd' }}>
                  <td style={{ textAlign: 'center' }}>{index + 1}</td>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{user.first_name}</td>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{user.last_name}</td>
                  <td style={{ textAlign: 'center' }}>{user.email}</td>
                  <td style={{ textAlign: 'center' }}>{user.business_name.join(', ')}</td>
                  <td style={{ textAlign: 'center' }}>{user.role}</td>
                  <td style={{ textAlign: 'center' }}>
                    <button type="button"
                      style={{ marginRight: '8px', background: '#52ab98', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px' }}
                      onClick={() => handleEdit(user.id)}>
                      Edit
                    </button>
                    <button type="button"
                      style={{ background: '#f44336', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px' }}
                      onClick={() => handleDelete(user.id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        {pageNumbers.map((number) => (
          <button key={number} onClick={() => paginate(number)}
            style={{
              backgroundColor: currentPage === number ? '#9FA6B2' : '#9FA6B280',
              color: 'white',
              border: 'none',
              padding: '5px 7px',
              cursor: 'pointer',
              borderRadius: '4px',
              marginRight: '5px',
            }}>
            {number}
          </button>
        ))}
      </div>

      {editedUser && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: '#fff',
              padding: '16px',
              borderRadius: '4px',
              width: '70%',
              overflow: 'auto',
              maxHeight: '80%',
            }}
          >
            <div>
              <form>
                <h2>Edit User</h2>
                <div style={flexContainerStyle}>
                  <div style={{ ...flexItemStyle }}>
                    <label htmlFor="editFirstName">First Name:</label>
                    <input
                      style={inputStyle}
                      type="text"
                      id="editFirstName"
                      value={editedUser.first_name}
                      onChange={(e) =>
                        setEditedUser({ ...editedUser, first_name: e.target.value })
                      }
                    />
                  </div>

                  <div style={{ ...flexItemStyle }}>
                    <label htmlFor="editLastName">Last Name:</label>
                    <input
                      style={inputStyle}
                      type="text"
                      id="editLastName"
                      value={editedUser.last_name}
                      onChange={(e) =>
                        setEditedUser({ ...editedUser, last_name: e.target.value })
                      }
                    />
                  </div>

                  <div style={{ ...flexItemStyle }}>
                    <label htmlFor="editEmail">Email:</label>
                    <input
                      style={inputStyle}
                      type="email"
                      id="editEmail"
                      value={editedUser.email}
                      onChange={(e) =>
                        setEditedUser({ ...editedUser, email: e.target.value })
                      }
                    />
                  </div>

                  <div style={{ ...flexItemStyle }}>
                    <label htmlFor="editPassword">Password:</label>
                    <input
                      style={{ ...inputStyle, width: 'calc(100% - 40px)' }}
                      type={showPassword ? 'text' : 'password'}
                      id="editPassword"
                      value={editedUser.password}
                      onChange={(e) => setEditedUser({ ...editedUser, password: e.target.value })}
                    />
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </div>

                  <div style={{ ...flexItemStyle }}>
                    <label htmlFor="edit_business_unit">Company Name:</label>
                    <select
                      id="edit_business_unit"
                      value={editedUser.business_unit || []}
                      onChange={(e) => {
                        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
                        setEditedUser({ ...editedUser, business_unit: selectedOptions });
                      }}
                      style={inputStyle}
                      multiple
                    >
                      <option value="" disabled>Select Company Name</option>
                      {business.map((business_unit) => (
                        <option key={business_unit.id} value={business_unit.id}>
                          {business_unit.business_unit_name}
                        </option>
                      ))}
                    </select>
                  </div>


                  <div style={{ ...flexItemStyle }}>
                    <label htmlFor="editRole">Role:</label>
                    <select
                      style={inputStyle}
                      id="editRole"
                      value={editedUser.role}
                      onChange={(e) =>
                        setEditedUser({ ...editedUser, role: e.target.value })
                      }
                    >
                      {userRoles.map((role) => (
                        <option key={role[0]} value={role[0]}>
                          {role[1]}
                        </option>
                      ))}
                    </select>
                  </div>

                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', marginRight: '5px' }}>
                  <button type="button" style={{ ...buttonStyle, marginRight: '5px' }} onClick={handleSaveEdit}>
                    Save
                  </button>
                  <button style={{ background: '#9FA6B2', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', marginRight: '5px' }} onClick={handleClose}>Close</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>

  );
};

export default UserList;

import AxiosInstance from '../Axios';

export const deleteUser = async (userId) => {
  try {
    const response = await AxiosInstance.delete(`/api/user_list/${userId}/`);
    return response.data; 
  } catch (error) {
    throw error;
  }
};

export const editUser = async (userId, updatedUserData) => {
    try {
      const response = await AxiosInstance.put(`/api/user_list/${userId}/`, updatedUserData);
      return response.data;
    } catch (error) {
      
      console.error('Error editing user:', error);
      throw error;
    }
  };

export const createUser = async (userData) => {
    try {
      const response = await AxiosInstance.post('/api/user_list/', userData);
      return response.data; 
    } catch (error) {
      throw error;
    }
  };


import AxiosInstance from '../Axios';

export const createFundAllocation = async (fundallocationData) => {
    try {
      const response = await AxiosInstance.post('/api/create_fund_allocation/', fundallocationData);
      return response.data; 
    } catch (error) {
      throw error;
    }
  };

  export const editFundAllocation = async (fundAllocationId, updatedfundallocation) => {
    try {
      const response = await AxiosInstance.put(`/api/edit_fund_allocation/${fundAllocationId}/`, updatedfundallocation);
      return response.data;
    } catch (error) {
      console.error('Error editing user:', error);
      throw error;
    }
  };

  export const deleteFundAllocation = async (fundAllocationId) => {
    try {
      const response = await AxiosInstance.delete(`/api/allocation_list/${fundAllocationId}/`);
      return response.data; 
    } catch (error) {
      throw error;
    }
  };

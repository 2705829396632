import React, { useState, useEffect } from 'react';
import AxiosInstance from '../Axios';
import Loader from '../services/Loader';
import { useAuth } from '../services/AuthContext';
import Toast from '../services/ToastService';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';

const Reports = () => {
    const { currentUser } = useAuth();
    const [reports, setReports] = useState({ allocation_logs: [], allocations: [], request_forms: [] });
    const [dailyreports, setDailyreports] = useState({ request_forms: [] });
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('allSummary');
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState('All');
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);


    useEffect(() => {
        const fetchData = async () => {
            try {

                const reportlist = await AxiosInstance.get(`/api/Fund_Manager_Reports_View/?date=${selectedDate}`);
                setReports(reportlist.data);

                const dailyreportlist = await AxiosInstance.get(`/api/Fund_Manager_Daily_Reports_View/?date=${selectedDate}`);
                setDailyreports(dailyreportlist.data);

                setLoading(false);
            } catch (error) {
                console.error("Error details:", error);
                Toast({ message: 'Error fetching data from server.', type: 'error' });
                setLoading(false);
            }
        };

        fetchData();
    }, [currentUser, selectedDate]);

    if (loading) {
        return <Loader />;
    }

    function formatAmountWithCommas(amount) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    const calculateTotalAmountPerBU = (reports, status) => {
        const filteredReports = reports.filter(report => report.status === status);
        const totalAmountPerBU = {};

        filteredReports.forEach(report => {
            if (totalAmountPerBU[report.business_unit_name]) {
                totalAmountPerBU[report.business_unit_name] += parseFloat(report.amount);
            } else {
                totalAmountPerBU[report.business_unit_name] = parseFloat(report.amount);
            }
        });

        return totalAmountPerBU;
    };


    const releasedReports = reports && reports.request_forms ? reports.request_forms.filter(report => {
        return report.status === 'Released' && report.released_date === selectedDate;
    }) : [];

    const liquidatedReports = reports && reports.request_forms ? reports.request_forms.filter(report => {
        return report.status === 'Liquidated' && report.liquidated_date === selectedDate;
    }) : [];

    const replenishedReports = reports && reports.request_forms ? reports.request_forms.filter(report => {
        return report.status === 'Replenished' && report.replenish_date === selectedDate;
    }) : [];

    const totalReleasedPerBU = calculateTotalAmountPerBU(releasedReports, 'Released');
    const totalLiquidatedPerBU = calculateTotalAmountPerBU(liquidatedReports, 'Liquidated');

    const totalReplenishedPerBU = calculateTotalAmountPerBU(replenishedReports, 'Replenished');

    const generatePDF = () => {
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: [205, 210]
        });
        pdf.setFont('times', 'normal');


        const text = `Company Summary Reports`;
        const textWidth = pdf.getTextDimensions(text).w;
        const centerX = (pdf.internal.pageSize.width - textWidth) / 2;
        pdf.text(text, centerX, 10);
        pdf.setFontSize(8);

        pdf.setFontSize(10);
        pdf.text(`Date:`, 160, 20);
        pdf.setFontSize(10);
        pdf.text(`${selectedDate} `, 170, 20);

        const table = document.querySelector('#allBusinessUnitSummaryTable');
        pdf.autoTable({
            html: table,
            startY: 30,
            didDrawPage: function (data) {
                pdf.setFont('times', 'bold');
                pdf.setFontSize(12);
                pdf.setFont('times', 'normal');
            }
        });

        pdf.output('dataurlnewwindow');
    };


    const generateDailyReportPDF = () => {
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: [205, 210]
        });
        pdf.setFont('times', 'normal');

        const text = `${selectedBusinessUnit} Daily Reports`;
        const textWidth = pdf.getTextDimensions(text).w;
        const centerX = (pdf.internal.pageSize.width - textWidth) / 2;
        pdf.text(text, centerX, 10);
        pdf.setFontSize(8);

        pdf.setFontSize(10);
        pdf.text(`Date:`, 160, 20);
        pdf.setFontSize(10);
        pdf.text(`${selectedDate} `, 170, 20);

        const table = document.querySelector('#BusinessUnitDailyReports');

        pdf.autoTable({
            html: table,
            startY: 30,
            didDrawPage: function (data) {
                pdf.setFont('times', 'bold');
                pdf.setFontSize(12);
                pdf.setFont('times', 'normal');
            }
        });


        pdf.setTextColor(60, 60, 60);
        pdf.setFontSize(11);

        pdf.output('dataurlnewwindow');
    };


    const allBusinessUnitSummary = (
        <div className="card" style={{ width: '100%', backgroundColor: '#f9fafb', padding: '20px', borderRadius: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                <div className="date-picker" style={{ marginBottom: '10px' }}>
                    <label htmlFor="selectedDate" style={{ marginRight: '10px', fontWeight: 'bold' }}>Select Date:</label>
                    <input
                        type="date"
                        id="selectedDate"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                        style={{
                            padding: '8px',
                            borderRadius: '5px',
                            border: '1px solid #ccc',
                            backgroundColor: '#fff',
                            cursor: 'pointer',
                            height: '40px',
                            width: '180px'
                        }}
                    />
                </div>
                <div style={{ textAlign: 'center', flex: '1' }}>{new Date(selectedDate).toDateString()}</div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        color="gray"
                        onClick={generatePDF}
                        sx={{ borderRadius: '4px', padding: '8px' }}
                    >
                        <PrintIcon />
                    </IconButton>
                </div>
            </div>
            <table id="allBusinessUnitSummaryTable" style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                    <tr style={{ borderBottom: '1px solid #ddd', background: '#f2f2f2' }}>
                        <th>Company Name</th>
                        <th>Starting Fund</th>
                        <th>Released</th>
                        <th>Liquidated</th>
                        <th>Replenished</th>
                        <th>Remaining Fund</th>
                    </tr>
                </thead>
                <tbody style={{ margin: '10px 0' }}>
                    {Object.keys((reports.allocation_logs || []).reduce((acc, curr) => ({ ...acc, [curr.business_name]: true }), {})).map(businessUnit => (
                        <tr key={businessUnit} style={{ borderBottom: '1px solid #ddd' }}>
                            <td style={{ textAlign: 'center' }}>{businessUnit}</td>
                            <td style={{ textAlign: 'center' }}>
                                {formatAmountWithCommas((reports.allocation_logs || [])
                                    .filter(allocationLog => allocationLog.business_name === businessUnit)
                                    .reduce((total, allocationLog) => total + parseFloat(allocationLog.amount), 0)
                                    .toFixed(2))}
                            </td>
                            <td style={{ textAlign: 'center' }}>{formatAmountWithCommas((totalReleasedPerBU[businessUnit] || 0).toFixed(2))}</td>
                            <td style={{ textAlign: 'center' }}>{formatAmountWithCommas((totalLiquidatedPerBU[businessUnit] || 0).toFixed(2))}</td>
                            <td style={{ textAlign: 'center' }}>{formatAmountWithCommas((totalReplenishedPerBU[businessUnit] || 0).toFixed(2))}</td>
                            <td style={{ textAlign: 'center' }}>
                                {formatAmountWithCommas((reports.allocations || [])
                                    .filter(allocations => allocations.business_name === businessUnit)
                                    .reduce((total, allocations) => total + parseFloat(allocations.amount), 0)
                                    .toFixed(2))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    const filteredReports = dailyreports && dailyreports.request_forms
        ? selectedBusinessUnit === 'All'
            ? dailyreports.request_forms.filter(report => report.released_date === selectedDate)
            : dailyreports.request_forms.filter(report => report.business_unit_name === selectedBusinessUnit && report.released_date === selectedDate)
        : [];

    const totalAmount = (filteredReports || []).reduce((total, report) => total + parseFloat(report.amount), 0);
    const formattedTotalAmount = formatAmountWithCommas(totalAmount.toFixed(2));

    const perBusinessUnitSummary = (
        <div>

            <div className="card" style={{ width: '100%', backgroundColor: '#f9fafb', padding: '20px', borderRadius: '10px' }}>
                <div className="dropdown-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="dropdown" style={{ marginBottom: '10px' }}>
                        <label htmlFor="businessUnit" style={{ marginRight: '10px', fontWeight: 'bold' }}>Select Company Name:</label>
                        <select
                            id="businessUnit"
                            value={selectedBusinessUnit}
                            onChange={(e) => setSelectedBusinessUnit(e.target.value)}
                            style={{
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                backgroundColor: '#fff',
                                cursor: 'pointer',
                                height: '40px',
                                marginRight: '10px'
                            }}
                        >
                            <option value="All">All</option>
                            {Array.from(new Set(dailyreports.request_forms.map(report => report.business_unit_name))).map(unit => (
                                <option key={unit} value={unit}>{unit}</option>
                            ))}
                        </select>
                    </div>
                    <div className="date-picker" style={{ marginBottom: '10px', display: 'inline-block' }}>
                        <label htmlFor="selectedDate" style={{ marginRight: '10px', fontWeight: 'bold' }}>Select Date:</label>
                        <input
                            type="date"
                            id="selectedDate"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            style={{
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                                backgroundColor: '#fff',
                                cursor: 'pointer',
                                height: '40px',
                                width: '180px'
                            }}
                        />
                    </div>
                </div>

                <div className="card" style={{ width: '100%', backgroundColor: '#f9fafb', borderRadius: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h4>Daily Reports</h4>
                    <div>{new Date(selectedDate).toDateString()}</div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton
                            color="gray"
                            onClick={generateDailyReportPDF}
                            sx={{ borderRadius: '4px', padding: '8px' }}
                        >
                            <PrintIcon />
                        </IconButton>
                    </div>
                </div>
                <table id="BusinessUnitDailyReports" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr style={{ borderBottom: '1px solid #ddd', background: '#f2f2f2' }}>
                            <th>Voucher No</th>
                            <th>Date</th>
                            <th>Activity</th>
                            <th>Status</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredReports.map(report => (
                            <tr key={report.id} style={{ borderBottom: '1px solid #ddd' }}>
                                <td style={{ textAlign: 'center' }}>{report.voucher_no}</td>
                                <td style={{ textAlign: 'center' }}>{new Date(report.released_date).toDateString()}</td>
                                <td style={{ textAlign: 'center' }}>{report.activity}</td>
                                <td style={{ textAlign: 'center' }}>{report.status}</td>
                                <td style={{ textAlign: 'center' }}>{formatAmountWithCommas(report.amount)}</td>
                            </tr>
                        ))}
                        <tr style={{ borderTop: '2px solid #000' }}>
                            <td colSpan="4" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total Amount:</td>
                            <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                {formattedTotalAmount}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );

    return (
        <div className="reports-container">
            <h2>Reports</h2>
            <div className="tab-buttons" style={{ margin: '10px 0' }}>
                <button
                    style={{
                        backgroundColor: activeTab === 'allSummary' ? '#ddd' : '#f2f2f2',
                        border: 'none',
                        padding: '10px 20px',
                        cursor: 'pointer',
                        marginRight: '10px',
                        borderRadius: '5px',
                    }}
                    onClick={() => setActiveTab('allSummary')}
                >
                    All Company Summary
                </button>
                <button
                    style={{
                        backgroundColor: activeTab === 'perSummary' ? '#ddd' : '#f2f2f2',
                        border: 'none',
                        padding: '10px 20px',
                        cursor: 'pointer',
                        marginRight: '10px',
                        borderRadius: '5px',
                    }}
                    onClick={() => setActiveTab('perSummary')}
                >
                    Per Company Summary
                </button>
            </div>
            <div className="tab-content">
                {activeTab === 'allSummary' ? allBusinessUnitSummary : perBusinessUnitSummary}
            </div>
        </div>
    );
};

export default Reports;




import AxiosInstance from '../Axios';

export const AssignBusinessUnit = async (AssignBusinessUnitData) => {
    try {
      const response = await AxiosInstance.post('/api/fund_list/BusinessToFund/', AssignBusinessUnitData);
      return response.data; 
    } catch (error) {
      throw error;
    }
  };

  export const deleteFundAssignment = async (FundAssignmentId) => {
    try {
      const response = await AxiosInstance.delete(`/api/fund_list/BusinessToFund/${FundAssignmentId}/`);
      return response.data; 
    } catch (error) {
      throw error;
    }
  };

  export const editFundAssignment = async (FundAssignmentId, updatedfundallocation) => {
    try {
      const response = await AxiosInstance.put(`/api/fund_list/BusinessToFund/${FundAssignmentId}/`, updatedfundallocation);
      return response.data;
    } catch (error) {
      console.error('Error editing user:', error);
      throw error;
    }
  };
  

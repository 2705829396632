import React, { useState, useEffect } from 'react';
import AxiosInstance from './Axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './services/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState('');
  const { login } = useAuth();
  const history = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showBusinessUnit, setShowBusinessUnit] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [userRole, setUserRole] = useState('');

  const handleLogin = async () => {
    try {
      const response = await AxiosInstance.post('api/login/', {
        email: email,
        password: password,
        selectedBusinessUnit: selectedBusinessUnit
      });

      if (response.data.message === 'User login successful.') {
        const token = response.data.token;
        localStorage.setItem('authToken', token);
        AxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        login({
          id: response.data.id,
          role: response.data.role,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          email: response.data.email,
          business_unit: selectedBusinessUnit
        });

        toast.success('Login successful!');

        switch (response.data.role) {
          case 'Admin':
            history('/Home');
            break;
          case 'Cost Controller':
            history('/CostControllerDashboard');
            break;
          case 'Finance Manager':
            history('/Dashboard');
            break;
          case 'Fund Custodian':
            history('/CustodianDashboard');
            break;
          default:
            history('/');
        }
        window.location.reload();
      }
    } catch (error) {
      toast.error('Login failed. Incorrect email or password.');
    }
  };

  useEffect(() => {
    const fetchBusinessUnits = async () => {
      try {
        if (passwordFocused) {
          const businessUnitsResponse = await AxiosInstance.post('api/check_business_unit_based_on_email/', {
            email: email,
          });

          setUserRole(businessUnitsResponse.data.role);

          if (businessUnitsResponse.data.businessUnits.length > 0) {
            setShowBusinessUnit(true);
            setBusinessUnits(businessUnitsResponse.data.businessUnits);
          } else {
            setShowBusinessUnit(false);
          }
        }
      } catch (error) {
        console.error('Error fetching business units:');
        if (error.response && error.response.status === 404) {
          console.log('Email not found');
        }
      }
    };

    fetchBusinessUnits();
  }, [email, passwordFocused]);

  return (
    <div style={styles.backgrounImage}>
      <div style={styles.container}>
        <h2 style={styles.heading}>Login</h2>
        <form style={styles.form}>
          <label style={styles.label}>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
          />
          <br />
          <label style={styles.label}>Password:</label>
          <div style={{ position: 'relative' }}>
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setPasswordFocused(true)}
              onKeyPress={(e) => e.key === 'Enter' && handleLogin()}
              style={styles.input}
            />
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              style={{ position: 'absolute', right: '5px', top: '50%', transform: 'translateY(-65%)' }}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </div>
          <br />
          {showBusinessUnit && userRole === 'Cost Controller' && (
            <>
              <label htmlFor="business_unit" style={styles.label}>Select Business Unit:</label>
              <select
                id="business_unit"
                value={selectedBusinessUnit}
                onChange={(e) => setSelectedBusinessUnit(e.target.value)}
                style={styles.inputs}
              >
                <option value="" disabled>Select Business Unit</option>
                {businessUnits.map((businessUnit, index) => (
                  <option key={index} value={businessUnit}>
                    {businessUnit}
                  </option>
                ))}
              </select>
            </>
          )}
          <button type="button" onClick={handleLogin} style={styles.button}>
            Login
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

const styles = {
  body: {
    background: '#fff',
    margin: 0,
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Montserrat, lato',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  container: {
    maxWidth: '400px',
    margin: 'auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    marginTop: '150px',
    background: '#EDEDED',
    overflow: 'hidden',
  },
  heading: {
    textAlign: 'center',
    color: '#333',
  },
  backgrounImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: 'url(/PTMS.png)',
    backgroundSize: '50% auto',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: '8px',
  },
  input: {
    marginBottom: '16px',
    padding: '8px',
    fontSize: '16px',
    width: 'calc(100% - 20px)',
  },
  inputs: {
    marginBottom: '16px',
    padding: '8px',
    fontSize: '16px',
    width: 'calc(100%)',
  },
  button: {
    background: '#14BDAD',
    color: '#000',
    padding: '10px',
    borderRadius: '4px',
    cursor: 'pointer',
    width: '50%',
    margin: 'auto',
  },
};

export default Login;

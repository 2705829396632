import React, { useState, useEffect } from 'react';
import AxiosInstance from '../Axios';
import { AssignBusinessUnit } from '../services/AssignBusinessUnitService';
import { deleteFundAssignment } from '../services/AssignBusinessUnitService';
import Toast from '../services/ToastService';
import { editFundAssignment } from '../services/AssignBusinessUnitService';
import Loader from '../services/Loader';
import { ToastContainer, } from 'react-toastify';
const AssignBusiness = () => {
  const [AssignbU, setAssignbU] = useState([]);
  const [business, setbusinessUnit] = useState([]);
  const [fund, setFundName] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [newAssignBusinessUnit, setAssignBusinessUnit] = useState({
    fund_name: '',
    business_units: '',
  });
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchfund = async () => {
      try {
        const response = await AxiosInstance.get('/api/fund_list/BusinessToFund/');
        setAssignbU(response.data);

        const fundResponse = await AxiosInstance.get('/api/fund_list/');
        setFundName(fundResponse.data);

        const businessResponse = await AxiosInstance.get('/api/businessUnit_list/');
        setbusinessUnit(businessResponse.data);
        setLoading(false);
      } catch (error) {
        Toast({ message: 'Error fetching data', type: 'error' });
        setLoading(false);
      }
    };

    fetchfund();
  }, []);
  if (loading) {
    return <Loader />;
  }

  const handleAssignBusinessUnit = async () => {
    try {
      const existingFundIndex = AssignbU.findIndex((existingFund) => existingFund.name === newAssignBusinessUnit.fund_name);

      if (existingFundIndex !== -1) {
        const updatedAssignbU = [...AssignbU];
        updatedAssignbU[existingFundIndex].business_units = [
          ...updatedAssignbU[existingFundIndex].business_units,
          ...newAssignBusinessUnit.business_units,
        ];

        setAssignbU(updatedAssignbU);
      } else {
        await AssignBusinessUnit(newAssignBusinessUnit);
        const updatedResponse = await AxiosInstance.get('/api/fund_list/BusinessToFund/');
        setAssignbU(updatedResponse.data);
      }

      setAssignBusinessUnit({
        fund_name: '',
        business_units: [],
      });

      Toast({ message: 'Business Unit Assigned successfully.', type: 'success' });
    } catch (error) {
      Toast({ message: 'Error Assigned Business Unit.', type: 'error' });
      if (error.response) {
        Toast({ message: 'Server response data.', type: 'error' });
      }
    }
  };

  const DeleteAssignBusinessUnit = async (FundAssignmentId) => {
    try {
      const isConfirmed = window.confirm('Are you sure you want to delete this Fund Assignment?');
      if (isConfirmed) {
        await deleteFundAssignment(FundAssignmentId);
        const updatedResponse = await AxiosInstance.get('/api/fund_list/BusinessToFund/');
        setAssignbU(updatedResponse.data);
        Toast({ message: 'Fund Assignment deleted successfully.', type: 'success' });
      } else {
        // User canceled the deletion
        Toast({ message: 'Deletion canceled.', type: 'success' });
      }
    } catch (error) {
      Toast({ message: 'Error deleting Fund Assignment.', type: 'error' });
    }
  };

  const handleEdit = (FundAssignmentId) => {
    const businessUnitToEdit = AssignbU.find((fund) => fund.id === FundAssignmentId);
    setEditItem(businessUnitToEdit);
  };

  const handleClose = () => {
    setEditItem(false);
  };


  const EditAssignBusinessUnit = async () => {
    try {
      if (editItem) {

        await editFundAssignment(editItem.id, {
          fund_name: editItem.fund_name,
          business_units: editItem.business_units,
        });

        setEditItem(null);
        const updatedResponse = await AxiosInstance.get('/api/fund_list/BusinessToFund/');
        setAssignbU(updatedResponse.data);
        Toast({ message: 'Business edited successfully.', type: 'success' });
      } else {
        Toast({ message: 'No business unit data to edit.', type: 'error' });
      }
    } catch (error) {
      Toast({ message: 'Error editing business unit.', type: 'error' });
    }
  };

  //Css style
  const formContainerStyle = {
    marginBottom: '10px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    maxWidth: '100%',
    margin: 'auto',
    background: '#f9fafb',
  };

  const flexContainerStyle = {
    display: 'flex',
    marginBottom: '8px',
  };

  const flexItemStyle = {
    flex: 1,
    marginRight: '8px',
  };

  const inputStyle = {
    width: '100%',
    padding: '8px',
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.2)',
  };


  const buttonStyle = {
    background: '#52ab98',
    color: 'white',
    border: 'none',
    padding: '8px 12px',
    cursor: 'pointer',
    borderRadius: '4px',
  };



  return (
    <div>

      <h2>Fund Assignment</h2>
      <form style={formContainerStyle}>
        <div style={flexContainerStyle}>
          <div style={{ ...flexItemStyle }}>
            <label htmlFor="fundName">Fund:</label>
            <select
              id="fundName"
              value={newAssignBusinessUnit.fund_name}
              onChange={(e) => setAssignBusinessUnit({ ...newAssignBusinessUnit, fund_name: e.target.value })}
              style={inputStyle}
            >
              <option value="" disabled>Select Fund</option>
              {fund.map((fundname) => (
                <option key={fundname.id} value={fundname.id}>
                  {fundname.name}
                </option>
              ))}
            </select>
          </div>

          <div style={{ ...flexItemStyle }}>
            <label htmlFor="businessUnit">Company Name:</label>
            <select
              id="business_unit"
              value={newAssignBusinessUnit.business_units || []}
              onChange={(e) => setAssignBusinessUnit({ ...newAssignBusinessUnit, business_units: Array.from(e.target.selectedOptions, option => option.value) })}
              style={inputStyle}
              multiple
            >
              {business.filter(business_unit => !AssignbU.some(assignedUnit => assignedUnit.business_units.includes(business_unit.id))).map((business_unit) => (
                <option key={business_unit.id} value={business_unit.id}>
                  {business_unit.business_unit_name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button style={buttonStyle} type="button" onClick={handleAssignBusinessUnit}>
            Create
          </button>
        </div>
      </form>
      <br />


      {/* Fund List */}
      <div style={formContainerStyle}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid #ddd', background: '#f2f2f2' }}>
              <th>No</th>
              <th>Fund</th>
              <th>Company Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {AssignbU
              .slice()
              .sort((a, b) => (a.name > b.name) ? 1 : -1)
              .map((fund, index) => (
                <React.Fragment key={fund.id}>
                  <tr style={{ borderBottom: '1px solid #ddd' }}>
                    <td style={{ textAlign: 'center' }}>{index + 1}</td>
                    <td style={{ textAlign: 'center' }}>{fund.name}</td>
                    <td>
                      <table style={{ width: '100%' }}>
                        <tbody>
                          {fund.business_units && fund.business_units.map((businessUnit, unitIndex) => (
                            <tr key={unitIndex}>
                              <td style={{ textAlign: 'center' }}>{fund.business_name && fund.business_name[unitIndex]}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <button
                        type="button"
                        style={{ marginRight: '8px', background: '#52ab98', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px' }}
                        onClick={() => handleEdit(fund.id)}
                      >
                        Edit
                      </button>
                      <button type="button"
                        style={{ background: '#f44336', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px' }}
                        onClick={() => DeleteAssignBusinessUnit(fund.id)}
                      >
                        Delete
                      </button>
                    </td>
                    <td style={{ textAlign: 'center' }}>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>

      {editItem && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: '#fff',
              padding: '16px',
              borderRadius: '4px',
              width: '50%',
              overflow: 'auto',
              maxHeight: '80%',
            }}
          >
            <form>
              <h2>Edit Fund Assignment</h2>
              <div style={flexContainerStyle}>
                <div style={{ ...flexItemStyle }}>
                  <label htmlFor="fundName">Fund Name:</label>
                  <select
                    id="fundName"
                    value={editItem.fund_name}
                    onChange={(e) => setEditItem({ ...editItem, fund_name: e.target.value })}
                    style={inputStyle}
                  >
                    <option value="" disabled>Select Fund</option>
                    {fund.map((fundname) => (
                      <option key={fundname.id} value={fundname.id}>
                        {fundname.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div style={{ ...flexItemStyle }}>
                  <label htmlFor="businessUnit">Company List:</label>
                  <select
                    id="business_unit"
                    value={editItem.business_units || []}
                    onChange={(e) => setEditItem({ ...editItem, business_units: Array.from(e.target.selectedOptions, option => option.value) })}
                    style={inputStyle}
                    multiple
                  >
                    {business.map((business_unit) => (
                      <option key={business_unit.id} value={business_unit.id}>
                        {business_unit.business_unit_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', marginRight: '5px' }}>
                <button style={{ ...buttonStyle, marginRight: '5px' }} type="button" onClick={EditAssignBusinessUnit}>
                  Save
                </button>
                <button style={{ background: '#9FA6B2', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', marginRight: '5px' }} onClick={handleClose}>Close</button>
              </div>
            </form>
          </div>
        </div>
      )}
      <ToastContainer />

    </div>

  );
};




export default AssignBusiness;

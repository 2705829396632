import AxiosInstance from '../Axios';

export const createBusinessUnit = async (businessUnitData) => {
    try {
      const response = await AxiosInstance.post('/api/businessUnit_list/', businessUnitData);
      return response.data; 
    } catch (error) {
      throw error;
    }
  };

  export const editBusinessUnit = async (businesUnitId, updatedBusinessUnit) => {
    try {
      const response = await AxiosInstance.put(`/api/businessUnit_list/${businesUnitId}/`, updatedBusinessUnit);
      return response.data;
    } catch (error) {
      console.error('Error editing user:', error);
      throw error;
    }
  };

  export const deleteBusinessUnit = async (businesUnitId) => {
    try {
      const response = await AxiosInstance.delete(`/api/businessUnit_list/${businesUnitId}/`);
      return response.data; 
    } catch (error) {
      throw error;
    }
  };

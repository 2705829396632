import AxiosInstance from '../Axios';

export const createPurchaseRequest = async (PurchaseRequestData) => {
    try {
      const response = await AxiosInstance.post('/api/create_request_form/', PurchaseRequestData);
      console.log('Response data:', response.data);
      return response.data; 
    } catch (error) {
      throw error;
    }
  };

  export const editPurchaseRequest = async (purchaseRequestId, PurchaseRequestData) => {
    try {
      const response = await AxiosInstance.put(`/api/edit_request_form/${purchaseRequestId}/`, PurchaseRequestData);
      return response.data;
    } catch (error) {
      console.error('Error editing purchase request:', error);
      throw error;
    }
  };
  
  

  export const deletePurchaseRequest = async (PurchaseRequestId) => {
    try {
      const response = await AxiosInstance.delete(`/api/forms_list/${PurchaseRequestId}/`);
      return response.data; 
    } catch (error) {
      throw error;
    }
  };

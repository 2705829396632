import React from 'react';
import { toast as showToast } from 'react-toastify';

const Toast = ({ message, type = 'success' }) => {
  const toastConfig = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  const showToastMessage = () => {
    switch (type) {
      case 'success':
        showToast.success(message, toastConfig);
        break;
      case 'error':
        showToast.error(message, toastConfig);
        break;
      case 'info':
        showToast.info(message, toastConfig);
        break;
      default:
        showToast(message, toastConfig);
    }
  };

  return <>{showToastMessage()}</>;
};

export default Toast;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Toast from '../services/ToastService';
import AxiosInstance from '../Axios';
import Loader from '../services/Loader';
import { useAuth } from '../services/AuthContext';

const Dashboard = () => {
    const history = useNavigate();
    const { currentUser } = useAuth();

    const [DeclinedPurchaseRequest, setDeclinedPurchaseRequest] = useState([]);
    const [ApprovedPurchaseRequest, setApprovedPurchaseRequest] = useState([]);
    const [LiquidatedPurchaseRequest, setLiquidatedPurchaseRequest] = useState([]);
    const [ReleasedPurchaseRequest, setReleasedPurchaseRequest] = useState([]);
    const [ReplenishedPurchaseRequest, setReplenishedPurchaseRequest] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchfund = async () => {
            try {
                const Declinedresponse = await AxiosInstance.get('/api/PurchaseRequest_Decline_List_View/');
                setDeclinedPurchaseRequest(Declinedresponse.data);

                const approvedResponse = await AxiosInstance.get('/api/PurchaseRequestApprovedListView/');
                setApprovedPurchaseRequest(approvedResponse.data);

                const Liquidatedresponse = await AxiosInstance.get('/api/Encoder_Liquidated_List_View/');
                setLiquidatedPurchaseRequest(Liquidatedresponse.data);

                const Releasedresponse = await AxiosInstance.get('/api/PurchaseRequestReleasedListView/');
                setReleasedPurchaseRequest(Releasedresponse.data);

                const Replenishedresponse = await AxiosInstance.get('/api/Cost_Controller_Replenish_View/');
                setReplenishedPurchaseRequest(Replenishedresponse.data);


                setLoading(false);
            } catch (error) {
                Toast({ message: 'Error fetching data from server.', type: 'error' });
                setLoading(false);
            }
        };

        fetchfund();
    }, [currentUser.business_unit]);

    if (loading) {
        return <Loader />;
    }

    const blue_card = '#fff';

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            backgroundColor: '#E6F7FF',
        },

        flexContainer1: {
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'center',
        },
        cardRow: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
        },
        card: {
            width: 'calc(33.33% - 20px)',
            borderRadius: '8px',
            margin: '10px',
            backgroundColor: blue_card,
            border: '1px solid #ccc',
        },
        smallCard: {
            width: 'calc(33.33% - 20px)',
            padding: '10px',
            borderRadius: '8px',
            backgroundColor: blue_card,
            margin: '10px',
            height: '25vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #ccc',
        },
        smallHeading: {
            textAlign: 'center',
            color: '#000',
            marginBottom: '8px',
        },
    };

    const GeneralManagerApproved = () => {
        history(`/GeneralManagerApproved/${11}`);
    };

    const GeneralManagerDecline = () => {
        history(`/GeneralManagerDeclined/${12}`);
    };


    const GeneralManagerReleased = () => {
        history(`/GeneralManagerReleased/${13}`);
    };


    const GeneralManagerLiquidated = () => {
        history(`/GeneralManagerLiquidated/${14}`);
    };

    const GeneralManagerReplenished = () => {
        history(`/GeneralManagerReplenished/${15}`);
    };



    return (
        <div >
            <div style={styles.flexContainer1}>
                <div style={styles.smallCard} onClick={() => GeneralManagerApproved()}>
                    <h3 style={styles.smallHeading}>Approved Fund Request </h3>
                    <div style={{ textAlign: 'center' }}>
                        <h2>{ApprovedPurchaseRequest.length}</h2>
                    </div>
                </div>

                <div style={styles.smallCard} onClick={() => GeneralManagerDecline()}>
                    <h3 style={styles.smallHeading}>Declined Fund Request </h3>
                    <div style={{ textAlign: 'center' }}>
                        <h2>{DeclinedPurchaseRequest.length}</h2>
                    </div>
                </div>


                <div style={styles.smallCard} onClick={() => GeneralManagerReleased()}>
                    <h3 style={styles.smallHeading}>Disbursed Fund Request</h3>
                    <div style={{ textAlign: 'center' }}>
                        <h2>{ReleasedPurchaseRequest.length}</h2>
                    </div>
                </div>

                <div style={styles.smallCard} onClick={() => GeneralManagerLiquidated()}>
                    <h3 style={styles.smallHeading}>Liquidated Fund Request</h3>
                    <div style={{ textAlign: 'center' }}>
                        <h2>{LiquidatedPurchaseRequest.length}</h2>
                    </div>
                </div>

                <div style={styles.smallCard} onClick={() => GeneralManagerReplenished()}>
                    <h3 style={styles.smallHeading}>Replenished Fund Request</h3>
                    <div style={{ textAlign: 'center' }}>
                        <h2>{ReplenishedPurchaseRequest.length}</h2>
                    </div>
                </div>

            </div>
        </div>
    );
};


export default Dashboard;

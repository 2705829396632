import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Toast from '../services/ToastService';
import AxiosInstance from '../Axios'; 
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Loader from '../services/Loader';
const CustodianDashboard = ( ) => {
    const history = useNavigate();
    const [fund_per_business, set_fund_per_business] = useState([]);
    const [startingFund, setsStartingFund] = useState([]);
    const [ReleaseAmount, setReleaseAmount] = useState([]);
    const [LiquidatedAmount, setLiquidatedAmount] = useState([]);
    const [TobeReleaseAmount, setTobeReleaseAmount] = useState([]);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
      const fetchfund = async () => {
        try {

          const businessResponse = await AxiosInstance.get('/api/AllocationListView/');
          set_fund_per_business(businessResponse.data);

          const staringFundResponse = await AxiosInstance.get('/api/Allocation_Log_Per_BU_View/');
          setsStartingFund(staringFundResponse.data);
          setsStartingFund(staringFundResponse.data);

          const releaseresponse = await AxiosInstance.get('/api/Cost_Controller_Release_View/');
          setReleaseAmount(releaseresponse.data);

          const tobereleaseresponse = await AxiosInstance.get('/api/Cost_Controller_To_Be_Release_View/');
          setTobeReleaseAmount(tobereleaseresponse.data);

          const liquidatedresponse = await AxiosInstance.get('/api/Cost_Controller_Liquidated_View/');
          setLiquidatedAmount(liquidatedresponse.data);

          setLoading(false);
        } catch (error) {
          Toast({ message: 'Error fetching data from server.', type: 'error' });
          setLoading(false);
        }
      };
  
      fetchfund(); 
    }, []); 

    
  if (loading) {
    return <Loader />; 
  }

  const blue_card = '#fff';
    const styles = {
      container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100vh',
      },
      flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: '20px',
      },
      card: {
        maxWidth: '1500px',
        borderRadius: '8px',
        margin: '10px',
        backgroundColor: blue_card,
      },
      heading: {
        textAlign: 'center',
        color: '#333',
        marginBottom: '16px',
      },
      paragraph: {
        color: '#555',
        fontSize: '16px',
      },
      smallCard: {
        flex: '1 1 200px', // Flex-grow, Flex-shrink, Flex-basis
        maxWidth: '400px',
        padding: '10px',
        borderRadius: '8px',
        backgroundColor: blue_card,
        margin: '10px',
        border: '1px solid #ccc',
        minHeight: '300px', // Set minimum height for the card
      },
      bigCard: {
        flex: 1,
        borderRadius: '8px',
        border: '1px solid #ccc',
        backgroundColor: blue_card,
        textcolor: "black",
        height: '20vh',
        padding: '10px',
        marginRigth: '20px',
        marginLeft: '10px',
      },
      smallHeading: {
        textAlign: 'center',
        color: '#000',
        marginBottom: '8px',
        position: 'sticky',
        top: '2px', 
        zIndex: '2',
        backgroundColor: blue_card,
      },
      smallParagraph: {
        color: '#000',
        fontSize: '14px',
      },
    };
    

  const tobeReleaseCardClick = () => {
    history(`/ToBeReleaseCard/${1}`);
  };
  
  const ReleaseCardClick = () => {
    history(`/ReleaseCard/${2}`);
  };

  const LiquidatedCardClick = () => {
    history(`/LiquidatedCard/${3}`);
  };

  const getStartingFund = () => {
    return [...new Set(startingFund.map((StartingFund) => StartingFund.business_unit_name))];
  };



  const ReservedFund = calculateReservedFund();

  function calculateReservedFund() {
    const totals = {};
    TobeReleaseAmount.forEach((tobeReleaseAmount) => {
      const { business_unit_name, amount } = tobeReleaseAmount;
      totals[business_unit_name] = (totals[business_unit_name] || 0) + parseFloat(amount);
    });
    return totals;
  }
  const getReservedFund = () => {
    return [...new Set(TobeReleaseAmount.map((tobeReleaseAmount) => tobeReleaseAmount.business_unit_name))];
  };


  const totalAmountByBusinessUnit = calculateTotalAmountByBusinessUnit();

  function calculateTotalAmountByBusinessUnit() {
    const totals = {};
    ReleaseAmount.forEach((releaseAmount) => {
      const { business_unit_name, amount } = releaseAmount;
      totals[business_unit_name] = (totals[business_unit_name] || 0) + parseFloat(amount);
    });
    return totals;
  }

  const getUniqueBusinessUnits = () => {
    return [...new Set(ReleaseAmount.map((releaseAmount) => releaseAmount.business_unit_name))];
  };


  const totalDataCount = calculateTotalDataCount();

function calculateTotalDataCount() {
  return TobeReleaseAmount.length;
}

  const totalLiquidatedAmount = calculatetotalLiquidatedAmount();
  function calculatetotalLiquidatedAmount() {
    const totals = {};
    LiquidatedAmount.forEach((liquidatedAmount) => {
      const { business_unit_name, amount } = liquidatedAmount;
      totals[business_unit_name] = (totals[business_unit_name] || 0) + parseFloat(amount);
    });
    return totals;
  }

  const getBusinessUnitsLiquidatedAmount = () => {
    return [...new Set(LiquidatedAmount.map((liquidatedAmount) => liquidatedAmount.business_unit_name))];
  };



function formatAmountWithCommas(amount) {
  if (amount !== undefined && amount !== null) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return ""; 
  }
}


  return (
    <div>
      <div style={styles.flexContainer}>

      <div style={styles.smallCard}>
        <h3 style={styles.smallHeading}>Starting Fund </h3>
       {startingFund.sort((a, b) => (a.business_unit_name > b.business_unit_name) ? 1 : -1).map((fund) => (
          <div key={fund.id} >
            <p style={styles.smallParagraph}>
            <strong>{fund.business_unit_name}</strong> - ₱ {formatAmountWithCommas(parseFloat(fund.amount).toFixed(2))}
            </p>
          </div>
        ))}
      </div>

        <div style={styles.smallCard}>
          <h3 style={styles.smallHeading}>Remaining Fund </h3>
          {fund_per_business.sort((a, b) => (a.business_name > b.business_name) ? 1 : -1).map((fund) => (
            <div key={fund.id}>
              <p style={styles.smallParagraph}>
                <strong>{fund.business_name}</strong> - ₱ {formatAmountWithCommas(parseFloat(fund.amount).toFixed(2))}
              </p>
            </div>
          ))}
        </div>

        <div style={styles.smallCard}>
          <h3 style={styles.smallHeading}>Reserved Fund </h3>
          {getReservedFund().sort().map((businessUnit) => {
            const reservedFund = ReservedFund[businessUnit] || 0;
            return (
              <div key={businessUnit} style={{ marginBottom: '10px' }}>
                <p style={styles.smallParagraph}>
                <strong>{businessUnit}</strong> - ₱ {formatAmountWithCommas(parseFloat(reservedFund).toFixed(2))}
                </p>
              </div>
            );
          })}
        </div>

        <div style={styles.smallCard} onClick={() => tobeReleaseCardClick()}>
          <h3 style={styles.smallHeading}>For Releasing </h3>
          <div style={{ textAlign: 'center' }}>
          <h2  style={{ color: '#000' }}>{totalDataCount}</h2> 
          </div>
        </div>

        <div style={styles.smallCard} onClick={() => ReleaseCardClick()}>
          <h3 style={styles.smallHeading}>Disbursed Amount </h3>
          {getUniqueBusinessUnits().sort().map((businessUnit) => (
            <div key={businessUnit} style={{ marginBottom: '10px' }}>
              <p style={styles.smallParagraph}>
                <strong>{businessUnit}</strong> - ₱ {formatAmountWithCommas(parseFloat(totalAmountByBusinessUnit[businessUnit]).toFixed(2))}
              </p>
            </div>
          ))}
        </div>


        <div style={styles.smallCard} onClick={LiquidatedCardClick}>
          <h3 style={styles.smallHeading}>Liquidated Amount</h3>
          {getBusinessUnitsLiquidatedAmount().sort().map((businessUnit) => (
            <div key={businessUnit} style={{ marginBottom: '10px' }}>
              <p style={styles.smallParagraph}>
                <strong>{businessUnit}</strong> - ₱ {formatAmountWithCommas(parseFloat(totalLiquidatedAmount[businessUnit]).toFixed(2))}
              </p>
            </div>
          ))}
        </div>

      </div>

      {/* Bottom two cards */}
      <div style={styles.flexContainer}>
      <div style={{...styles.bigCard, height: '50vh', width: '99%', flex: 'none'}}>
          <h3 style={styles.smallHeading}>Overview</h3>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={getStartingFund().map((businessUnit) => ({
                name: businessUnit,
                StartingFund: startingFund.find((fund) => fund.business_unit_name === businessUnit)?.amount || 0,
                RemainingFund: fund_per_business.find((fund) => fund.business_name === businessUnit)?.amount || 0,
                ReservedFund: ReservedFund[businessUnit] || 0,
                Disbursed: totalAmountByBusinessUnit[businessUnit] || 0,
                Liquidated: totalLiquidatedAmount[businessUnit] || 0,
              }))}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="StartingFund" name="Starting Fund" fill="#0C356A" />
              <Bar dataKey="RemainingFund" name="Remaining Fund" fill="#E74C3C" />
              <Bar dataKey="ReservedFund" name="Reserved Fund" fill="#F6AA54" />
              <Bar dataKey="Disbursed" name="Disbursed" fill="#3D3D3D" />
              <Bar dataKey="Liquidated" name="Liquidated" fill="#00A9B5" />
            </BarChart>
          </ResponsiveContainer>
         
              
        </div>
      </div>

    </div>
    
  );
};


export default CustodianDashboard;

import React, { useState, useEffect } from 'react';
import AxiosInstance from '../Axios';
import { useAuth } from '../services/AuthContext';
import Toast from '../services/ToastService';
import { useNavigate } from 'react-router-dom';
import { deletePurchaseRequest } from '../services/PurchaseRequestService';
import Loader from '../services/Loader';

const CostControllerDecline = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [PurchaseRequest, setPurchaseRequest] = useState([]);
  const [selectedPurchaseRequest, setSelectedPurchaseRequest] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const currentUserBusinessUnit = currentUser.business_unit;

        const response = await AxiosInstance.get('/api/PurchaseRequest_Decline_List_View/');
        const DeclinedPurchaseRequests = response.data;
        const filteredDeclinedPurchaseRequests = DeclinedPurchaseRequests.filter(request => request.business_unit_name === currentUserBusinessUnit);
        setPurchaseRequest(filteredDeclinedPurchaseRequests);
        setLoading(false);
      } catch (error) {
        Toast({ message: 'Error fetching data from server.', type: 'error' });
      }
      setLoading(false);
    };
    fetchData();
  }, [currentUser]);

  if (loading) {
    return <Loader />;
  }

  const handleViewDetail = (purchaseRequest) => {
    setSelectedPurchaseRequest(purchaseRequest);
  };

  const handleClosePopup = () => {
    setSelectedPurchaseRequest(null);
  };

  const navigateToDashboard = () => {
    navigate('/CostControllerDashboard');
  };

  const handleDelete = async () => {
    try {
      const isConfirmed = window.confirm('Are you sure you want to delete this Fund Request?');
      if (isConfirmed && selectedPurchaseRequest) {
        const purchaseRequestId = selectedPurchaseRequest.id;

        await deletePurchaseRequest(purchaseRequestId);

        const updatedResponse = await AxiosInstance.get('/api/PurchaseRequest_Decline_List_View/');
        setPurchaseRequest(updatedResponse.data);
        Toast({ message: 'Fund Request deleted successfully.', type: 'success' });

        setSelectedPurchaseRequest(null);
      } else {
        Toast({ message: 'Fund Request deletion cancelled.', type: 'success' });
      }
    } catch (error) {
      Toast({ message: 'Error deleting Fund Request:', type: 'error' });
    }
  };

  function formatDate(dateString) {
    const dateParts = dateString.split("-");
    const formattedDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    return formattedDate.toLocaleDateString("en-US");
  }

  function formatAmountWithCommas(amount) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  //Css style
  const formContainerStyle = {
    marginBottom: '10px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    maxWidth: '100%',
    margin: 'auto',
    background: '#f9fafb',
  };
  return (
    <div>
      <div style={formContainerStyle}>
        <h4>
          <label htmlFor="RemainingFund">Decline List</label>
        </h4>
        <div style={{ background: '#EFF3F4' }}>
          {PurchaseRequest.map((purchaseRequest) => (
            <div
              key={purchaseRequest.id}
              style={{
                marginBottom: '16px',
                padding: '8px',
                border: '1px solid #ddd',
                borderRadius: '4px',
                position: 'relative',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                <strong style={{ marginRight: '10px' }}>Voucher No:</strong>
                <span style={{ marginRight: '10px' }}>{purchaseRequest.voucher_no}</span>
                <strong style={{ marginRight: '10px' }}>Date Requested:</strong>
                <span style={{ marginRight: '10px' }}> {formatDate(purchaseRequest.date_requested)}</span>
              </div>
              <button
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '8px',
                  transform: 'translateY(-50%)',
                  padding: '8px',
                  cursor: 'pointer',
                  backgroundColor: '#52ab98',
                  color: '#ffffff',
                  borderRadius: '4px',
                  border: '1px solid #52ab98',
                }}
                onClick={() => handleViewDetail(purchaseRequest)}
              >
                View Detail
              </button>
            </div>
          ))}
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px', marginRight: '5px' }}>
        <button
          style={{ background: '#9FA6B2', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', marginRight: '5px' }}
          onClick={navigateToDashboard}
        >
          Back
        </button>
      </div>

      {selectedPurchaseRequest && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: '#fff',
              padding: '16px',
              borderRadius: '4px',
              width: '70%',
              overflow: 'auto',
              maxHeight: '80%',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <strong>Voucher No:</strong> {selectedPurchaseRequest.voucher_no}
              </div>
              <div>
                <strong>Date Requested:</strong> {formatDate(selectedPurchaseRequest.date_requested)}
              </div>
            </div>
            <div>
              <strong>Reviewed By:</strong> {selectedPurchaseRequest.requested_by}
            </div>
            <div>
              <strong>Covered Date:</strong> {formatDate(selectedPurchaseRequest.covered_from)} to {formatDate(selectedPurchaseRequest.covered_to)}
            </div>
            <div>
              <strong>Activity:</strong> {selectedPurchaseRequest.activity}
            </div>
            <div>
              <strong>Profit Center:</strong> {selectedPurchaseRequest.profit_center}
            </div>
            <div>
              <strong>Company Name:</strong> {selectedPurchaseRequest.business_unit_name}
            </div>
            <div>
              <strong>Status:</strong> {selectedPurchaseRequest.status}
            </div>
            <div>
              <strong>Items:</strong>
            </div>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr style={{ borderBottom: '1px solid #ddd', background: '#f2f2f2' }}>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Unit of Measure</th>
                  <th>Price</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {selectedPurchaseRequest.items.map((item, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: 'center' }}>{item.descriptions}</td>
                    <td style={{ textAlign: 'center' }}>{formatAmountWithCommas(item.quantity)}</td>
                    <td style={{ textAlign: 'center' }}>{item.uom}</td>
                    <td style={{ textAlign: 'center' }}>{formatAmountWithCommas(item.price)}</td>
                    <td style={{ textAlign: 'center' }}>{formatAmountWithCommas(item.item_total_amount)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8px' }}>
              <div>
                <strong>Total Amount:</strong> {formatAmountWithCommas(selectedPurchaseRequest.amount)}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
              <button style={{ background: '#DC4C64', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px', marginRight: '10px' }} onClick={handleDelete}>Delete</button>
              <button style={{ background: '#9FA6B2', color: 'white', border: 'none', padding: '8px 12px', cursor: 'pointer', borderRadius: '4px' }} onClick={handleClosePopup}>Close</button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};


export default CostControllerDecline;

import axios from 'axios';

export const getCookie = (name) => {
  const cookieArray = document.cookie.split(';').map(cookie => cookie.trim());
  for (const cookie of cookieArray) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (cookieName === name) {
      return cookieValue;
    }
  }
  return null;
};

const baseUrl = process.env.NODE_ENV === 'production'
  ? 'https://ptmsbackend.classify.com.ph/'
  : 'http://127.0.0.1:7000/';

const token = localStorage.getItem('authToken');

const AxiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token}` : '',
  },
});

AxiosInstance.interceptors.request.use(config => {
  if (['post', 'put', 'delete'].includes(config.method)) {
    const csrfToken = getCookie('csrftoken');
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken;
    }
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default AxiosInstance;

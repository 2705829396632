import AxiosInstance from '../Axios';

export const createFund = async (fundData) => {
    try {
      const response = await AxiosInstance.post('/api/fund_list/', fundData);
      return response.data; 
    } catch (error) {
      throw error;
    }
  };

  export const editFund = async (fundId, updatedfund) => {
    try {
      const response = await AxiosInstance.put(`/api/fund_list/${fundId}/`, updatedfund);
      return response.data;
    } catch (error) {
      console.error('Error editing user:', error);
      throw error;
    }
  };

  export const deleteFund = async (fundId) => {
    try {
      const response = await AxiosInstance.delete(`/api/fund_list/${fundId}/`);
      return response.data; 
    } catch (error) {
      throw error;
    }
  };
